import React, { useEffect, useState } from "react";
import styled from '@emotion/styled'
import { mq } from "../utilities/media-queries.js";
import Price from "../../img/features/price.png"
import Button from "./../../components/button-comp.js"
import { useNavigate } from 'react-router-dom';

const HeroArea = styled.div`
    width: 100%;
    height: auto;
    padding: 50px 5vw 0 5vw;
    background-color: #222;
    color: #fff;
    display: flex;
    flex-direction: column;
    

    ${mq[1]} {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding-top: 50px;
    },

    & > div {
        width: 100%;
        word-break: keep-all ;
        text-align: center;
        

        ${mq[1]} {
            width: 48%;
            text-align: left;
        }
    }


    
`

const H1 = styled.h1`
    font-size: 2em;
    line-height: 0.5em;
    margin-bottom: 20px;

    ${mq[1]} {
        font-size: 2.7em;
        line-height: 1.1em;
    }
`

const Image = styled.div`
    max-width: 100%;
    display: block;
    margin: auto;

    & > img {
        max-width: 100%;
        display: block;
        margin: auto;
    }

    ${mq[1]} {
        width: 24%;
        text-align: right;
    }
`
const Desc = styled.div`
  
  margin: 10px 0;
  font-size: 1em;
  word-break: keep-all;
  width: 100%;
  
  margin-bottom: 20px;


  ${mq[1]} {
    
    font-size: 1.3em;
  }
`


const Buttons = styled.div`
    width: 100%;
    margin: 30px 0;

    & > button {
        width: 100%;

        ${mq[1]} {
            width: auto;
        }
    } 
`


function LoginPricing(props) {
    const navigate = useNavigate();

    return(
        <HeroArea>
            <div>

                <H1>
                   Pricing
                </H1>

                <Desc>
                    Choose a plan that works for your business
                </Desc>

                <Buttons>
                    <Button style="ter" text="View Pricing"  onClick={() => {navigate('/pricing') }} />
                </Buttons>

            

            </div>

            <Image>

            <img src={Price} />
            {/* <div>
                        
                        <div className="pricing-table">
                            <div className="pricing-row">
                                    <div className="pricing-cell">
                                        <h3>Basic</h3>
                                        <small>Includes</small>
                                        <p>
                                            Editable Menu - 1<br />
                                            Laminated QR Codes - 10 <br />
                                        </p>
                                    </div>
                                    <div className="pricing-cell pricing-cell-left-border">
                                        <b>FREE</b>
                                    </div>
                            </div>

                            <div className="pricing-row">
                                    <div className="pricing-cell">
                                        <h3>Pro</h3>
                                        <small>Includes</small>
                                        <p>
                                            Editable Menus - 5<br />
                                            Guest Alerts
                                        </p>
                                    </div>
                                    <div className="pricing-cell pricing-cell-left-border">
                                        <b>$5.99</b>
                                        <div><small>per month</small></div>
                                    
                                    </div>
                            </div>

                        
                        </div>
                    

                </div> */}

            </Image>

        </HeroArea>
    );
}

export default LoginPricing;