import React, { useEffect, useState } from "react";
import { css, cx } from '@emotion/css'
import styled from '@emotion/styled'
import AlertDetails from '../components/alert-details.js'
import InfoIcon from '@material-ui/icons/Info';

const AlertBody = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px 20px 10px 0 ;
    padding: 10px;
    width: 70%;
    max-width: 300px;
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    font-size: 0.8em;
    cursor: pointer;
    
    '&:hover:' {
        background-color: #000;
      }
`

const AlertIcon = styled.div`
    width: 40px;
    margin-right: 10px
`

const AlertText = styled.div`
    width: 75%;
`
const AlertTextTitle = styled.div`
    font-weight: 700;
`

const AlertCopy = styled.div`
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const ReadMore = styled.span`
    color: blue;
`

function Alert(props) {

    const [modalOpen, setModalOpen] = useState(false);




    function openAlert() {
        setModalOpen(!modalOpen)
    }   

    function handleModalChange() {
        setModalOpen(!modalOpen)
    }
    
    return(
        <>
        <AlertDetails color={props.color} open={modalOpen} onChange={handleModalChange} Title={props.AlertTitle} Copy={props.AlertCopy} />

        <AlertBody onClick={openAlert}>
            
            <AlertIcon>
                <InfoIcon />
            </AlertIcon>

            <AlertText>
                
                <AlertTextTitle>
                    {props.AlertTitle}
                </AlertTextTitle>

                <AlertCopy>
                    {props.AlertCopy}
                </AlertCopy>
                <ReadMore>Read More</ReadMore>

            </AlertText>
        
            

        </AlertBody>
        </>
    );
}

export default Alert;