import React, { useEffect, useRef, useState } from "react";
import firebase from "firebase/app"
import "firebase/auth"
import styled from '@emotion/styled'
import PageAlert from "../../components/alerts/page-alert";
import ButtonTW from "../../components/button-tw";
import HeaderLogo from "./../../img/logos/logo-alt.png"
import { useNavigate, Link } from 'react-router-dom';
import { useUserContext } from "../../context/user-context";
import Loading from "../../components/utilities/loading";

const Area = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 50px 5vw;
    background-color: #4b6bfb;
    color: #fff;
    text-align: center;
    overflow-y: scroll;
`
const CardBox = styled.div`
    
    background-color: #fff;
    width: 60%;
    
    max-width: 400px;
    padding: 20px;
    border-radius: 15px;
    color: #111;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: auto;

`

const Logo = styled.div`
    font-size: 2em;    
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > img {
        width: 120px;
        height: 120px;
    }
`

const Desc = styled.div`
  font-size: 1em;
  word-break: keep-all;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;



`


function Reg() {

    const navigate = useNavigate();
    const { user, logoutUser, sendVeri } = useUserContext();
    const [resendEmailSuccessfull, setResendEmailSuccessfull] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(5)
    
    useEffect(() => {
        if(!user || user.email == undefined) {
            navigate("/");
        } else {
            send();
        }
    },[user])

    // useEffect(() => {
    //     if(resendEmailSuccessfull) {
    //         let timer2 = setTimeout(() => {
                
    //             setCount(count -1)
        
    //         }, 1000);

    //         return () => {
    //         clearTimeout(timer2);
    //         };
    //     }
    // },[count, resendEmailSuccessfull])

    

    async function send() {
        setLoading(true);
        
        
        sendVeri()
        .then((result) => {
            setLoading(false);
            if (result) {
                setResendEmailSuccessfull(true);
            } else {
                console.log("not")
            }    
        });
        
    }

    return(
        <>
            <>
            {!resendEmailSuccessfull ?
                <Area>
                    <div className="mt-100">
                        <Logo>
                            <img src={HeaderLogo} /> 
                        Verify your e-mail
                        </Logo>
                        

                        <Desc>
                            {user&& user.email &&
                                <>
                                We have sent an e-mail to <strong>{user.email}</strong><br />
                                </>
                            }
                            Click the link in the e-mail to verify and proceed with access to your menu.
                        </Desc>

                    </div>

                    Didn't recieve a verification e-mail?<br />
                    <div className="inline">Please check your spam folder or Resend verification e-mail</div>
                    

                    <div className="mt-20 flex flex-col gap-6">
                        <ButtonTW style="tertiary" onClick={send}>Resend verification e-mail</ButtonTW>
                        <span className="font-bold cursor-pointer" onClick={logoutUser}>Exit verification</span>
                    </div>

                </Area>
                :
                <Area>
                    <Logo>
                        <img src={HeaderLogo} /> 
                        Verification e-mail sent!
                    </Logo>
                    <Desc>
                        Click on the link in your e-mail to get setup with your menu.<br />
                        Important: Please do not exit the verification process at this point
                    </Desc>
                </Area>
            }
            </>

            {showAlert&&
                <PageAlert message={alertMessage} type={alertType} setShowAlert={!setShowAlert} />
            } 

            {loading&&
                <Loading />
            }
        </>
    )
}

export default Reg;