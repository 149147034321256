import React, { useEffect, useState, useRef } from "react";
import { css, cx } from '@emotion/css'
import styled from '@emotion/styled'
import { mq } from "../utilities/media-queries.js";
import List from "../../img/dash/list.png"
import Tab from "../../img/dash/tab.png"

const Card = styled.div`
  
  width: 45%;
  margin-right: 15px;
  text-align: center;
  background-color: #fff;
  border: ${props => props.active ? "2px solid blue" : "1px solid #ccc"};
  cursor: pointer;
  

  ${mq[1]} {
    width: 30%;
    max-width: 200px;
  }
`

const Image = styled.img`
  max-width: 100%;
  display: block
`




function LayoutCard(props, {children}) {

    const [layoutTab, setLayoutTab] = useState(props.data);
    const [loading, setLoading] = useState(props.loading);

    const update = () => {
        
        props.updateLayout()

    }

    // useEffect(() => {
    //     setLoading(props.loading);
    // },[props.loading])

    return(
        <>
        <Card active={props.data ? true : false} onClick={() => update()}>
           <Image src={Tab} />
        </Card>

        <Card active={props.data ? false : true} onClick={() => update()}>
            <Image src={List} />
        </Card>


        </>
    )
}

export default LayoutCard;