import React, { useEffect, useState } from "react";
import styled from '@emotion/styled'
import { mq } from "../utilities/media-queries.js";
import Lostres from "../../img/clients/los-tres-amigos.png"
// import Picazo from "./../img/clients/picazo.png"
import Thefreeze from "../../img/clients/the-freeze.png"
import Geeta from "../../img/clients/geeta.png"
import Marcelas from "../../img/clients/Cafeteria-Marcelas.png"
import JibaritosDineIn from "../../img/clients/Jibaritos-Y-Mas.png"
import BirrieriaOcotlan from "../../img/clients/Birrieria-Ocotlan.png"
import RedHotRanch from "../../img/clients/Red-Hot-Ranch.png"
import Taquizas from "../../img/clients/Taquizas-a-Domicilio.png"
import JibaritosCarryOut from "../../img/clients/Jibaritos-Y-Mas-Carry-Out.png"
import CalumetFisheries from "../../img/clients/calumet-fisheries.png"
import Locochon from "../../img/clients/locochon.png"
import ElJibaro from "../../img/clients/eljibaro.png"
import Jacks from "../../img/clients/jacks-hot-dogs.png"
import Clients from "../../components/client-carousel.js"


const HeroArea = styled.div`
    width: 100%;
    height: auto;
    padding: 50px 5vw 0 5vw;
    background-color: #4470E2;
    color: #fff;
    display: flex;
    flex-direction: column;
    

    ${mq[1]} {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding-top: 50px;
    },

    & > div {
        width: 100%;
        word-break: keep-all ;
        text-align: center;
        

        ${mq[1]} {
            width: 48%;
            text-align: left;
        }
    }


    
`

const H1 = styled.h1`
    font-size: 2em;
    line-height: 0.5em;
    margin-bottom: 20px;

    ${mq[1]} {
        font-size: 2.7em;
        line-height: 1.1em;
    }
`

const Image = styled.div`
    max-width: 100%;
    display: block;
    margin: auto;

    & > img {
        max-width: 100%;
        display: block;
        margin: auto;
    }

    ${mq[1]} {
        width: 24%;
        text-align: right;
    }
`
const Desc = styled.div`
  
  margin: 10px 0;
  font-size: 1em;
  word-break: keep-all;
  width: 100%;
  
  margin-bottom: 20px;


  ${mq[1]} {
    
    font-size: 1.3em;
  }
`

const Buttons = styled.div`
    width: 100%;
    margin: 30px 0;

    & > button {
        width: 100%;

        ${mq[1]} {
            width: auto;
        }
    } 
`


function LoginCustomers(props) {

    return(
        <>
            <div className="global-padding-container text-center-mobile clients">
            <br /><h1 className="sectionTitle">Our Customers</h1><br /><br />
            <div className="carousel-container">
            
                <Clients imgsrc={JibaritosDineIn} url="https://da-menus.com/#/euJhhaeNsW" />
                <Clients imgsrc={Jacks} url="https://da-menus.com/#/hKj6gxMFhR" />
                <Clients imgsrc={Marcelas} url="https://da-menus.com/#/2ixgk3lUQs" />
                <Clients imgsrc={Thefreeze} url="https://da-menus.com/#/9kADZBeigp" />
                <Clients imgsrc={RedHotRanch} url="https://da-menus.com/#/EOiLKMnXCZ" />
                <Clients imgsrc={BirrieriaOcotlan} url="https://da-menus.com/#/Z9v3slAMbv" />
                <Clients imgsrc={ElJibaro} url="https://da-menus.com/#/PLH8BWLxUf" />
                <Clients imgsrc={CalumetFisheries} url="https://da-menus.com/#/NYhzGIwhtQ" />
                
                <Clients imgsrc={Locochon} url="https://da-menus.com/#/qyjFMUwO0C" />
                <Clients imgsrc={Taquizas} url="https://da-menus.com/#/GatBYrXqZe" />
                <Clients imgsrc={Lostres} url="https://da-menus.com/#/BH2OU8oIUp" />
                
                <Clients imgsrc={JibaritosCarryOut} url="https://da-menus.com/#/PTOhpoW4tC" />
                <Clients imgsrc={Geeta} url="https://da-menus.com/#/gqcnyo9Mgc" />
                {/* <Clients imgsrc={Picazo} url="http://menu.dabuzzer.com/picazo-taqueria/menu.pdf" /> */}
                

            </div>
        </div>
        </>
    );
}

export default LoginCustomers;