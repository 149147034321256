import React, { useEffect, useState } from "react";
import "../../App.css"

function PageAlert(props) {
    useEffect(() => {
          
        const timer1 = setTimeout(() => props.hideAlert, 1000);

        return () => {
        clearTimeout(timer1);
        };
    },[]);
    
    return (

        <>
            {props.type == "success" && 
                <div className="flex justify-center">
                        <div className="alert alert-success absolute w-[50%] z-[1000] alertupdown">
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            <span>{props.message}</span>
                        </div>
                </div>
            }

            {props.type == "error" && 
                <div className="flex justify-center ">
                        <div className="alert alert-warning absolute w-[50%] z-[1000] alertupdown">                            
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                            <span>{props.message}</span>
                        </div>
                </div>
            }
        </>

        

    )
}

export default PageAlert;