import React, { useEffect, useRef, useState } from "react";
import firebase from "firebase/app"
import "firebase/auth"
import styled from '@emotion/styled'
import ButtonTW from "../components/button-tw";
import HeaderLogo from "./../img/logos/logo-alt.png"
import { useNavigate } from 'react-router-dom';
import { useUserContext } from "../context/user-context";


const Area = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 50px 5vw;
    background-color: #4b6bfb;
    color: #fff;
    text-align: center;
    overflow-y: scroll;
`
const CardBox = styled.div`
    
    background-color: #fff;
    width: 95%;
    max-width: 500px;
    padding: 20px;
    border-radius: 15px;
    color: #111;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: auto;

`

const Logo = styled.div`
    font-size: 2em;    
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > img {
        width: 120px;
        height: 120px;
    }
`

const Desc = styled.div`
  font-size: 1em;
  word-break: keep-all;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;



`


function SignUp() {

    const navigate = useNavigate();
    const emailref = useRef();
    const passref = useRef();
    const conpassref = useRef();
    const nameref = useRef();
    const { user, logoutUser, registerPassUser } = useUserContext();
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [showPass, setShowPass] = useState(true)
    const [errors, setErrors]  = useState(
        {
            emailError : "",
            nameError : "",
            passError : "",
            cpassError : ""
        }
    )


    const resetErrors = (string) => {
        // setIsSubmitted(false);
        var errorName
        switch(string) {
            case nameref:
                setErrors(prevState => ({
                    ...prevState,
                    nameError : ""
                }));
            break

            case emailref:
                setErrors(prevState => ({
                    ...prevState,
                    emailError : ""
                }));
            break

            case passref:
                setErrors(prevState => ({
                    ...prevState,
                    passError : ""
                }));
            break

            case conpassref:
                setErrors(prevState => ({
                    ...prevState,
                    cpassError : ""
                }));
            break
        }

        
    }

    const validateEmail = (inpemail) => {
        return String(inpemail)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    var passFormat = /^(?=.*[0-9])(?=.*[a-z])(?=.*\d)(?!.* ).{8,}$/;
    var nameFormat = /^(([A-Za-z]+[\-\']?)*([A-Za-z]+)?\s)+([A-Za-z]+[\-\']?)*([A-Za-z]+)?$/;
    


    function isEmpty(a) {
        if(a.trim() === "") {
            return true;
        }
        else return false;
    }

    function Submit(e) {
        e.preventDefault();
        setIsSubmitted(true);
        const email = emailref.current.value;
        const password = passref.current.value;
        const conpassword = conpassref.current.value;
        const name = nameref.current.value;

        if(isEmpty(name)) {
            setErrors(prevState => ({
                ...prevState,
                nameError: "Please enter your full name"
            }));
        }

        if(isEmpty(email)) {
            setErrors(prevState => ({
                ...prevState,
                emailError: "Please enter an e-mail address"
            }));
        }

        if(isEmpty(password)) {
            setErrors(prevState => ({
                ...prevState,
                passError: "Please enter a password"
            }));
        }

        if(isEmpty(conpassword)) {
            setErrors(prevState => ({
                ...prevState,
                cpassError: "Please enter a password"
            }));
        }


        // check for empty before proceeding
        function FieldsEmptyCheck() {
            if(isEmpty(name) || isEmpty(email) || isEmpty(password) || isEmpty(conpassword)) {
                return true
            } 
            else {
                return false
            }
        }

        function Validate() {
            
            if(!validateEmail(email)){
                setErrors(prevState => ({
                    ...prevState,
                    emailError: "Please enter a valid e-mail address"
                }));
            }

            if(!nameFormat.test(name)) {

                setErrors(prevState => ({
                    ...prevState,
                    nameError: "Please enter a valid full name"
                }));
            }


            if(password !== conpassword) {
                setErrors(prevState => ({
                    ...prevState,
                    passError: "Passwords do not match",
                    cpassError : "Passwords do not match"
                }));
            }
            
            if(password.length < 8 ) {
                setErrors(prevState => ({
                    ...prevState,
                    passError: "Min 8 characters"
                }));
            } else if(!passFormat.test(password)) {
                console.log(passFormat.test(password))
                setErrors(prevState => ({
                    ...prevState,
                    passError: "Password should have atleast one number",
                }));
            }

            if(isEmpty(errors.nameError) && isEmpty(errors.emailError) && isEmpty(errors.passError) && isEmpty(errors.cpassError)) {
                
                registerPassUser(email,password,name)
            }
        }
        
        if(!FieldsEmptyCheck()) {
            Validate();
        } 

        
            
    }


    return(
        <Area>
            <div className="mt-100">
                <Logo>
                    <img src={HeaderLogo} /> 
                   Sign Up
                </Logo>
                

                <Desc>
                   Enter details below to create an account
                </Desc>

            </div>
            <CardBox>
                
                    <div className="form-control w-full mb-4">
                        <label className="label">
                            <span className="label-text">Full name</span>
        
                        </label>
                        <input type="text" className="input input-bordered w-full" ref={nameref} required onFocus={() => resetErrors(nameref)} />
                        <label className={isSubmitted && !isEmpty(errors.nameError) ? "label" : "label hidden"}>
                            <span className="label-text text-error">{errors.nameError}</span>
                        </label>
                    </div>

                    <div className="form-control w-full mb-4">
                        <label className="label">
                            <span className="label-text">E-mail address</span>
                        
                        </label>
                        <input type="text" className="input input-bordered w-full" ref={emailref} required onFocus={() => resetErrors(emailref)} />
                        <label className={isSubmitted && !isEmpty(errors.emailError) ? "label" : "label hidden"}>
                            <span className="label-text text-error">{errors.emailError}</span>
                        </label>
                    </div>
                    
                    <div className="form-control w-full mb-4">
                        <label className="label">
                            <span className="label-text">Password</span>
                            <a className="link link-primary text-sm" onClick={() => setShowPass(!showPass)}>{showPass ? "Show" : "Hide"}</a>
                        </label>
                        <input type={showPass ? "password" : "text"} className="input input-bordered w-full" ref={passref} required onFocus={() => resetErrors(passref)} />
                        <label className={isSubmitted && !isEmpty(errors.passError) ? "label" : "label hidden"}>
                            <span className="label-text text-error ">{errors.passError}</span>
                        </label>
                    </div>
                    
                    <div className="form-control w-full mb-4">
                        <label className="label">
                            <span className="label-text">Confirm password</span>
                        </label>
                        <input type={showPass ? "password" : "text"} className="input input-bordered w-full" ref={conpassref} required onFocus={() => resetErrors(conpassref)} />
                        <label className={isSubmitted && !isEmpty(errors.cpassError) ? "label" : "label hidden"}>
                            <span className="label-text text-error">{errors.cpassError}</span>
                        </label>
                    </div>
                    
                    {/* <div className="form-control w-full mb-4">
                        <label className="label">
                            <span className="label-text">Code (optional)</span>
                        
                        </label>
                        <input type="text" className="input input-bordered w-full" />
                       
                    </div> */}

                    <ButtonTW style="primary" onClick={Submit}>
                        Sign Up
                    </ButtonTW>
                    <div className="h-4"></div>
                    <ButtonTW 
                        style="secondary"
                        onClick={() => navigate('/')}
                    >
                        Cancel
                    </ButtonTW>
            </CardBox>
        </Area>
    )
}

export default SignUp