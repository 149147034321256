import React, { useEffect, useState, useRef } from "react";
import "./../App.css"
import "firebase/auth"
import {Helmet} from "react-helmet";
import Loading from "../components/utilities/loading";
import MainLayout from "../components/nav/main-layout";
import { PostData, checkUD, getData } from "../components/utilities/data-handling";


function Rewards() {

    return (
        <MainLayout>
            <h3 className="text-3xl font-bold">Foodie Rewards</h3>
            <div className="text-md">A rewards program for your customers</div>
        </MainLayout>
    );

}

export default Rewards