import React, { useEffect, useState } from "react";
import styled from '@emotion/styled'
import Manage from "../../img/features/alert.png"
import { mq } from "../utilities/media-queries.js";

const HeroArea = styled.div`
    width: 100%;
    height: auto;
    padding: 50px 5vw 0 5vw;
    background-color: #ed6a5a;
    color: #fff;
    display: flex;
    flex-direction: column;
    

    ${mq[1]} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 50px;
    },

    & > div {
        width: 100%;
        word-break: keep-all ;
        text-align: center;
        

        ${mq[1]} {
            width: 48%;
            text-align: right;
        }
    }


    
`

const H1 = styled.h1`
    font-size: 2em;
    line-height: 0.5em;
    margin-bottom: 20px;

    ${mq[1]} {
        font-size: 2.7em;
        line-height: 1.1em;
    }
`

const Image = styled.div`
    max-width: 100%;
    display: block;
    margin: auto;

    & > img {
        max-width: 100%;
        display: block;
        margin: auto;
    }

    ${mq[1]} {
        width: 24%;
        text-align: right;
    }
`
const Desc = styled.div`
  
  margin: 10px 0;
  font-size: 1em;
  word-break: keep-all;
  width: 100%;
  
  margin-bottom: 20px;


  ${mq[1]} {
    
    font-size: 1.3em;
  }
`


function LoginAlert(props) {

    return(
        <HeroArea>
            <div>

                <H1>
                    Guest Alerts
                </H1>

                <Desc>
                    A guest can send you a notification (to your device) via the QR code menu, when they need service.
                </Desc>

            

            </div>

            <Image>
                <img src={Manage} />
            </Image>

        </HeroArea>
    );
}

export default LoginAlert;