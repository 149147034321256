import React, { useEffect, useRef, useState } from "react";
import firebase from "firebase/app"
import "firebase/auth"
import styled from '@emotion/styled'
import PageAlert from "../../components/alerts/page-alert";
import ButtonTW from "../../components/button-tw";
import HeaderLogo from "./../../img/logos/logo-alt.png"
import { Link, useNavigate } from 'react-router-dom';
import { useUserContext } from "../../context/user-context";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { auth } from "../../firebase"
import Loading from "../../components/utilities/loading";
import { Card, setRef } from "@material-ui/core";

const Area = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 50px 5vw;
    background-color: #4b6bfb;
    color: #fff;
    text-align: center;
    overflow-y: scroll;
`
const CardBox = styled.div`
    
    background-color: #fff;
    width: 95%;
    max-width: 500px;
    padding: 20px;
    border-radius: 15px;
    color: #111;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: auto;

`

const Logo = styled.div`
    font-size: 2em;    
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 70%;

    & > img {
        width: 120px;
        height: 120px;
    }
`

const Desc = styled.div`
  font-size: 1em;
  word-break: keep-all;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;



`


function ResetPassword() {
    
    let resStatus = 0; 
    const navigate = useNavigate();
    const passref = useRef();
    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_API_EXT)
    const [verifyType, setVerifyType] = useState("");
    const [passError, setPassError] = useState("");
    const [emailAdd, setEmailAdd] = useState("");
    const [uid, setUID] = useState('')
    const [menuid, setMenuid] = useState('')
    const [uidName, setUIDName] = useState('')
    const [uidEmail, setUIDEmail] = useState('')
    const [passResetSuccessfull, setPassResetSuccessfull] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { user } = useUserContext();
    const [showPass, setShowPass] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [loading, setLoading] = useState(false);


    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
      
    
    const mode = params.mode;
    const actionCode = params.oobCode;
    const continueUrl = params.continueUrl;
    var passFormat = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

    const AccessExisting = () => {
        setAlertMessage("Please contact us - contactdamenus@gmail.com");
        setAlertType("success");
        setShowAlert(true);
    }

    const FetchUser = (userUID) => {
        fetch(apiUrl + "/menuUser/" + userUID )
        .then(res => {
            resStatus = res.status;
            return res.json();
        }).then((result) => {
            console.log(result);
            navigate("/");

        }).catch((err) => {
            return err;
        })
    }

    const Register = () => {
        
        setLoading(true)

        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            
            },
            body: JSON.stringify({ 
                name: uidName,
                email: uidEmail
            })
        };
    
        
        const url = apiUrl + "/menuUser/register/" + uid
        console.log(url)
        fetch(url, requestOptions)
        .then(res => {
            resStatus = res.status;
            return res.json();
        })
        .then(
            (result) => {
                switch (resStatus) {
                    case 200:
                        console.log('success')
                        setLoading(false)
                        // IF 2 menus then have the user pick one
                        navigate("/dashboard/" + result.menuID)
                        break
                }
            },

            // (error) => {
            //     setAlertMessage(error);
            //     setAlertType("error");
            //     setShowAlert(true);
            //     
            // }
        ).catch((err) => {
            console.log(err);
        })
    }

    const RegisterExisting = () => {
        
        setLoading(true)

        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            
            },
            body: JSON.stringify({ 
                name: uidName,
                email: uidEmail
            })
        };
    
        
        const url = apiUrl + "/menuUser/register/" + uid + menuid
        console.log(url)
        fetch(url, requestOptions)
        .then(res => {
            resStatus = res.status;
            return res.json();
        })
        .then(
            (result) => {
                switch (resStatus) {
                    case 200:
                        console.log('success')
                        setLoading(false)
                        // IF 2 menus then have the user pick one
                        navigate("/dashboard/" + result.menuID)
                        break
                }
            },

     
        ).catch((err) => {
            console.log(err);
        })
    }

    function handleResetPassword(actionCode) {

        // Verify the password reset code is valid.
        verifyPasswordResetCode(auth, actionCode).then((email) => {
            const accountEmail = email;
            setEmailAdd(email)
            
        }).catch((error) => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
        });
    }

    function resetPass(newPassword) {

        // Save the new password.
        confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
            // Password reset has been confirmed and new password updated.
            setPassResetSuccessfull(true);
        }).catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        });
    }

    useEffect(() => {
        console.log(emailAdd)
    },[emailAdd])
      
    useEffect(() => {

        console.log(mode, actionCode, continueUrl);

        if(mode == "verifyEmail") {
            setVerifyType("verifyEmail")
            
        } else if(mode == "resetPassword") {
            setVerifyType("resetPassword")
            handleResetPassword(actionCode)
        } else if(mode == "" || mode == undefined) {
            navigate("/");
        }
        

    },[])

    useEffect(() => {
        if(user) {
            setUID(user.uid);
            setUIDEmail(user.email);
            setUIDName(user.displayName)
            FetchUser(user.uid)
        } else {
            // no user ask to sign in
        }
    },[user])
    



    function isEmpty(a) {
        if(a.trim() === "") {
            return true;
        }
        else return false;
    }
    
    function Submit(e) {
        e.preventDefault();
        setIsSubmitted(true);
        const pass = passref.current.value;

        if(isEmpty(pass)) {
            setPassError("Please enter a password");

        } else if(pass.length < 8 ) {
            setPassError("Min 8 characters");
            
        } else if(!passFormat.test(pass)) {
            setPassError("Password should have atleast one number");
        } else {
            resetPass(pass)
            
        }
        
    }


    return(
        <>
        {verifyType == "resetPassword" && 
            <>
            {!passResetSuccessfull ?

                <Area>
                    <div className="mt-100">
                        <Logo>
                            <img src={HeaderLogo} /> 
                        Reset Password
                        </Logo>
                        

                        <Desc>
                        Enter your new password below
                        </Desc>


                    </div>
                    <CardBox>
                            {emailAdd &&
                            <div className="form-control w-full mb-4">
                                <label className="label">
                                    <span className="label-text">Email</span>
                
                                </label>
                                <div className="text-left label mt-0 pt-0">{emailAdd}</div>
                                <label className={isSubmitted && !isEmpty(passError) ? "label" : "label hidden"}>
                                    <span className="label-text text-error">{passError}</span>
                                </label>
                            </div>
                            }
                        
                            <div className="form-control w-full mb-4">
                                <label className="label">
                                    <span className="label-text">New Password</span>
                                    <a className="link link-primary text-sm" onClick={() => setShowPass(!showPass)}>{showPass ? "Show" : "Hide"}</a>
                                </label>
                                
                                <input type={showPass ? "password" : "text"} className="input input-bordered w-full" ref={passref} onFocus={()=>setPassError("")}/>
                                <label className={isSubmitted && !isEmpty(passError) ? "label" : "label hidden"}>
                                    <span className="label-text text-error">{passError}</span>
                                </label>
                            </div>

                            <ButtonTW style="primary"
                                onClick={Submit}
                            >
                                Save
                            </ButtonTW>
                            <div className="h-4"></div>
                            
                    </CardBox>
                </Area>
                :
                <Area>
                    <Logo>
                        <img src={HeaderLogo} /> 
                        Password Successfully updated!

                        <Link className="text-md font-normal text-dmwhite underline" to="/">Sign in to your account</Link>
                    </Logo>
                </Area>
            }
            </>
        }

        {verifyType == "verifyEmail" && 
            <>
            {user ?
                <Area>
                    <Logo>
                        <img src={HeaderLogo} /> 
                        <div className="text-5xl pb-2">Welcome Back!</div>
                        <div className="text-xl font-normal mt-2">
                            Thank you for verifying your e-mail <br />
                            Let's get you setup
                        </div>
                    </Logo>
                    <div className="flex flex-row justify-center w-[90%] max-w-[500px] gap-5 py-10 h-[200px]">
                        <ButtonTW style="box" onClick={Register}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Create a new Menu
                        </ButtonTW>
                        <ButtonTW style="box" onClick={()=>document.getElementById('my_modal_3').showModal()}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
                            </svg>
                            

                            Access an existing menu
                        </ButtonTW>
                    </div>

                    <dialog id="my_modal_3" className="modal">
                        <div className="modal-box text-left bg-dmwhite text-dmbasetext min-h-[30%]">
                            <form method="dialog">
                            {/* if there is a button in form, it will close the modal */}
                            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                            </form>
                            <h3 className="font-bold text-lg mt-4">Access and Existing Menu</h3>
                            <div className="mt-2">
                                Please contact us for access to an existing menu.
                                All access requests will need to be verified with the menu admin. <br />
                                <div className="my-4 mb-8 text-left text-sm">
                                    <b>In your email, please include</b>
                                    <p>1. Menu ID</p>
                                    <p>2. Your email address</p>
                                    <p>3. Add the menu admin in cc:</p>
                                </div>
                                
                            </div>
                            <div className="mt-2 mb-4">
                                <a href="mailto:contactdamenus@gmail.com?subject=Access Request: Exiting Menu">Contact Us</a>
                            </div>
                            {/* <div className="text-sm">Enter the Menu ID and access code of the menu you'd like access to. Only the menu admin can </div>
                            <label className="form-control w-full max-w-xs mt-8">
                                <div className="label">
                                    <span className="label-text">Menu ID</span>
                                    <span className="label-text-alt">Top Right label</span>
                                </div>
                                <input type="text" placeholder="Type here" className="input input-bordered w-full max-w-xs" />
                            </label> */}
                        </div>
                    </dialog>
                    
                </Area>

                :

                <Area>
                    <Logo>
                        <img src={HeaderLogo} /> 
                        {/* <div className="text-5xl pb-2">Sign in</div> */}
                        <div className="text-xl font-normal my-4 flex flex-col items-center">
                             <div className="font-bold text-2xl mb-8">Sign in to proceed</div>
                             
                        </div>
                        <CardBox>
                            <div className="card mb-6 bg-dmsecondary text-dmbasetext text-left">
                                <div className="card-body text-sm font-normal">
                                    <b>Trouble verifing?</b>
                                    <p>Use the same device</p>
                                    <p>Do not log out or exit verification</p>
                                    <a href="mailto:contactdamenus@gmail.com">Contact us</a>
                            
                                </div>
                            </div>
                            <ButtonTW style="primary" onClick={() => {navigate('/') }}>
                                Sign In
                            </ButtonTW>
                        </CardBox>
                    </Logo>
                </Area>
            }
            </>
        }


        {showAlert&&
            <PageAlert message={alertMessage} type={alertType} setShowAlert={!setShowAlert} />
        } 

        {loading&&
            <Loading />
        }


        </>
    )
}

export default ResetPassword;