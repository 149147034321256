import React, { useEffect, useState } from "react";
import '../css/item-details.css';
import { Cancel, ArrowBackIosOutlined, Clear} from '@material-ui/icons';
import { IconButton } from '@material-ui/core'
import { css } from '@emotion/css'

function AlertDetails(props) {

  const [isOpen, setIsOpen] = useState(false)
  const [detailData, setDetailData] = useState('')
  
  useEffect(() => {
    setIsOpen(props.open);
  }, [props.open]);

  function handleModalClose() {
    setIsOpen(!isOpen)
    props.onChange();
  }



  if(isOpen) {
    return (
      
        <div className="item-details">
          <div 
              className={css`
                  background-color: ${((props.color == undefined)||(props.color.background == null)||(props.color.background == "")) ? '#ed6a5a' : props.color.background};
                  width: 100%;
                  height: 80px;
                  position: relative;
                  padding-right: 20px;
                  justify-content: flex-end;
                  align-items: center;
                  display: flex;
              `}
            
            >
              
              <div onClick={handleModalClose} 
                className={css`
                    color: ${((props.color == undefined)||(props.color.textcolor == null)||(props.color.textcolor == "")) ? '#ffffff' : props.color.textcolor};
                `}
              >
                  <Clear />
              </div>

            </div>


            <div className="text-content">
              <h1 className={css`
                color: #000;
              `}
              >
                {props.Title}</h1>
              <div className="desc">{props.Copy.replace(/\\n/g,'\n')}</div>
              
            </div>
        </div>
      
    );
  }
  else {
    return (
      null
    );
  }
}

export default AlertDetails;
