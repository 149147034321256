import React, { useEffect, useRef, useState } from "react";
import firebase from "firebase/app"
import "firebase/auth"
import styled from '@emotion/styled'
import ButtonTW from "../../components/button-tw";
import HeaderLogo from "./../../img/logos/logo-alt.png"
import { useNavigate, Link } from 'react-router-dom';
import { useUserContext } from "../../context/user-context";


const Area = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 50px 5vw;
    background-color: #4b6bfb;
    color: #fff;
    text-align: center;
    overflow-y: scroll;
`
const CardBox = styled.div`
    
    background-color: #fff;
    width: 95%;
    max-width: 500px;
    padding: 20px;
    border-radius: 15px;
    color: #111;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: auto;

`

const Logo = styled.div`
    font-size: 2em;    
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > img {
        width: 120px;
        height: 120px;
    }
`

const Desc = styled.div`
  font-size: 1em;
  word-break: keep-all;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;



`


function ForgotPassword() {

    const navigate = useNavigate();
    const emailref = useRef();
    const [emailError, setEmailError] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { resetPassword } = useUserContext();
    const [passEmailSuccessfull, setPassEmailSuccessfull] = useState(false)

    const validateEmail = (inpemail) => {
        return String(inpemail)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    function isEmpty(a) {
        if(a.trim() === "") {
            return true;
        }
        else return false;
    }
    
    function Submit(e) {
        e.preventDefault();
        setIsSubmitted(true);
        const email = emailref.current.value;

        if(isEmpty(email)) {
            setEmailError("Please enter your e-mail address");
        } else if(!validateEmail(email)) {
            setEmailError("Please enter a valid e-mail address");
        } else {
            const result = resetPassword(email)
            if (result) {
                alert("yay")
            }
        }
        
    }


    return(
        <>
        {!passEmailSuccessfull ?
        <Area>
            <div className="mt-100">
                <Logo>
                    <img src={HeaderLogo} /> 
                   Forgot Password?
                </Logo>
                

                <Desc>
                   Enter your email below and we will send you a link to reset your password
                </Desc>

            </div>
            <CardBox>
                
                    <div className="form-control w-full mb-4">
                        <label className="label">
                            <span className="label-text">E-mail address</span>
        
                        </label>
                        <input type="text" className="input input-bordered w-full" ref={emailref} onFocus={()=>setEmailError("")}/>
                        <label className={isSubmitted && !isEmpty(emailError) ? "label" : "label hidden"}>
                            <span className="label-text text-error">{emailError}</span>
                        </label>
                    </div>

                    <ButtonTW style="primary"
                        onClick={Submit}
                    >
                        Reset Password
                    </ButtonTW>
                    <div className="h-4"></div>
                    <ButtonTW 
                        style="secondary"
                        onClick={() => navigate('/')}
                    >
                        Back
                    </ButtonTW>
            </CardBox>
        </Area>
        :
        <Area>
            <Logo>
                <img src={HeaderLogo} /> 
                Password reset e-mail sent!

                <Link className="text-md font-normal text-dmwhite underline" to="/">Sign in to your account</Link>
            </Logo>
        </Area>
        }
        </>
    )
}

export default ForgotPassword;