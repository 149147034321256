import { React, createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase"
import { createUserWithEmailAndPassword, onAuthStateChanged, updateProfile, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const UserContext = createContext({})

export const useUserContext = () => useContext(UserContext)

export const UserContextProvider = ({children}) => {

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState();
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const unsubscribe = onAuthStateChanged(auth, res => {
            res ? setUser(res) : setUser(null);
            setError("");
            setLoading(false);
        })

        return unsubscribe;

    },[])

    const registerPassUser = (email, password, name) => {

        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            updateProfile(auth.currentUser, {
                displayName: name,
                emailVerified: false,
            }).then(() => {
                navigate("/")
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setError(errorCode);
                return errorCode
            });
            
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setError(errorCode)
            
        });
        

    }

    const resetPassword = (email) => {
        sendPasswordResetEmail(auth, email)
        .then(() => {
            return true;
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setError(errorCode);
        });
    }

    const signInEmailPassUser = (email, password) => {

        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setError(errorCode);
        });

    }

    const signInUserGoogle = () => {
        
        setLoading(true);
        const provider = new GoogleAuthProvider();

        signInWithPopup(auth, provider)
        .then((result) => {
            
            const credential = GoogleAuthProvider.credentialFromResult(result);
            
            const token = credential.accessToken;
            const user = result.user;
            
        })
        .catch((error) => {setError(error.message);})
        .finally(() => setLoading(false));


    }

    const logoutUser = () =>{
        signOut(auth).then(() => {
            console.log("signedOUt")
            navigate("/")
          }).catch((error) => {
            // An error happened.
          });
    }

    // const forgotPassword = (email) => {
    //     ///
    // }

    const sendVeri = () => {
        return sendEmailVerification(auth.currentUser)
        .then(() => {
            return true;
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setError(errorCode);
        });
    }

    const contextValue = {
        user,
        loading, 
        error,
        registerPassUser,
        signInEmailPassUser,
        signInUserGoogle,
        resetPassword,
        sendVeri,
        logoutUser
    }

    return(
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    )
}