import React, { Children, useEffect, useState } from "react";
import ButtonTW from "../button-tw";

function ModalTW(props) {

    


    return (
        <>
        {props.open && 
        <div class="fixed flex items-center justify-center w-[100%] h-[100%] z-100 bg-dmmodal bg-opacity-70 left-0 top-0" aria-labelledby="modal-title" role="dialog" aria-modal="true">
             <div class="relative transform overflow-hidden rounded-lg bg-dmwhite text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div class="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                        {props.title}
                    </h3>
                    <div class="mt-2 mb-6">
                        <p class="text-sm break-keep">
                           {props.children}
                        </p>
                    </div>
                    
                    
                    <div class="bg-gray-50 py-2 sm:flex sm:flex-row-reverse ">
            
                        <ButtonTW style="secondary" onClick={props.onClickSec}>
                            {props.secCTA}
                        </ButtonTW>
                        <div class="w-4"></div>
                        <ButtonTW style="primary" onClick={props.onClickPri}>
                            {props.priCTA}
                        </ButtonTW>
                    </div>
                </div>
                
            </div>
        </div>
        }
        </>
    );
}

export default ModalTW