import React, { useEffect, useState } from "react";
import '../css/item-details.css';
import { Cancel, ArrowBackIosOutlined, Clear} from '@material-ui/icons';
import { IconButton } from '@material-ui/core'
import { css, cx } from '@emotion/css'
import styled from '@emotion/styled'
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from "react-player/youtube";


const Modal = styled.div` 
    width: 100%;
    height: 100vh !important;
    background-color: #ed6a5a;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    position: fixed;
    z-index: 100;
    
`

const AdContainer = styled.div` 

  width: 90%;
  height: 350px;
  background-color: #eee;
  position: relative;

`

function Ads(props) {

  const [isOpen, setIsOpen] = useState(true)
  const { currentPath } = props

  function adsModalClose() {
    setIsOpen(false)
  }




  


  if(isOpen) {
    return (
      
        <Modal>
          

          <AdContainer>

            <ReactPlayer url='https://www.youtube.com/watch?v=_pO9dlwuXZU'
                width='100%'
                height='100%'
                playing={true}
                controls={true}
                className="react-player"
              />

          </AdContainer>
          
          <CloseIcon className={css`

                  font-size: 50px;
                  margin-top: 50px

          `}
          
              onClick={adsModalClose}
          />
        </Modal>
      
    );
  }
  else {
    return (
      null
    );
  }
}

export default Ads;
