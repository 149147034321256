import React, { useEffect, useState } from "react";
import "./../App.css"
import { useNavigate } from "react-router-dom";
import { TextField, IconButton, CircularProgress, Icon } from '@material-ui/core'
import Button from "./../components/button-comp.js"
import { Link } from "react-router-dom";
import Obfuscate from 'react-obfuscate';
import Modal from  "./../components/modal.js"
import { FaBolt, FaGoogle, FaQrcode } from "react-icons/fa";
import LoginHero from "../components/login/login-hero";
import PageAlert from "../components/alerts/page-alert";
import LoginBrand from "../components/login/login-brand";
import LoginManage from "../components/login/login-manage";
import LoginAlert from "../components/login/login-alert";
import LoginQR from "../components/login/login-qr-codes";
import LoginPricing from "../components/login/login-pricing";
import LoginCustomers from "../components/login/login-customers";
import { useUserContext } from "../context/user-context";
  

function Login() {

    const navigate = useNavigate();
    let resStatus = 0; 
    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_API_EXT)
    const [registered, setRegistered] = useState(false)
    const [uid, setUID] = useState('')
    const [uidName, setName] = useState('')
    const [uidEmail, setEmail] = useState('')
    const [loading, setloading] = useState(false)
    const [loginState, setLoginState] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalCon, setModalCon] = useState("")
    const [retid, setRetid] = useState("")
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    const { user, logoutUser, error } = useUserContext();

    useEffect(() => {
        // Update the document title using the browser API

          if (user) {
            var UID = user.uid;
            setEmail(user.email)
            setName(user.displayName)
            // setloading(true);
            const emailVerified = user.emailVerified;
            console.log(user.emailVerified)


            setModalCon("loader")
            // setModalOpen(true)  

            setUID(UID);
            FetchUser(UID);
            setLoginState(true);

            // if(emailVerified) {
                
            // }  else {
            //     setModalCon("not-verified")
            // }
            

            
          } else {
            // No user is signed in.
            setLoginState(false);
          }
        
    }, [user]);

    useEffect(() => {
        if(error) {
            setShowAlert(true);
            setAlertMessage(error)
            setAlertType("error")
        }
    }, [error])

    const signout = () => {
        logoutUser();
        setModalOpen(false);
    }

    
    const handleModalChange = () => {
        setModalOpen(!modalOpen)
    }

    const withEmail = () => {
        setModalCon("with-email")
        setModalOpen(true)
    }

    const contactUs = () => {
        setModalCon("contact")
        setModalOpen(true)
    }

    const printit = () => {

        setModalCon("printing")
        setModalOpen(true)

    }

    

    const registerExisting = (childData) => {

        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            
            },
            body: JSON.stringify({ 
                name: uidName,
                email: uidEmail
            })
        };
        
        setloading(true)
        const url = apiUrl + "/menuUser/registerExisting/" + uid + "/" + childData
        fetch(url, requestOptions)
        .then(res => {
            resStatus = res.status;
        })
        .then(
            () => {
                switch (resStatus) {
                    case 200:
                        setloading(false)
                        console.log('success')
                        navigate("/dashboard/" + childData)
                        break

                    case 204:
                        setloading(false)
                        alert("Menu ID does not exist")
                        break
                }
            },

            (error) => {
                switch (resStatus) {
                    
                    case 500:
                    setloading(false)
                    console.log("Server Error, Please refresh")
                    break
                }
            }
        )

    }

    const FetchUser = (userUID) => {
        fetch(apiUrl + "/menuUser/" + userUID )
        .then(res => {
            resStatus = res.status;
            return res.json();
        })
        .then(
            (result) => {

                switch (resStatus) {
                    case 200:
                        
                        if(Array.isArray(result)) {

                            setModalCon(result);
                            console.log(result);
                            setloading(false)
                            setRegistered(true);
                            if(result.length > 1) {
                                setModalOpen(true);
                            }
                            
                            
                            
                        } else {
                            
                            setModalOpen(false);
                            setloading(false)
                            setRetid(result.menuID)
                            navigate("/dashboard/" + result.menuID)
                            
                            
                        } 
                        
                        break
                }

            },

            (error) => {
                switch (resStatus) {
                    case 204:
                    //User is Not Registered
                    navigate("/reg")
                    // setloading(false)
                    // setRegistered(false);
                    // setModalOpen(true);
                    // setModalCon("not-reg")

                    break

                    case 500:
                    setloading(false)
                    alert("Server Error, Please refresh")
                    break
                }
            }
        )
    }



    return (
        <>
            <Modal open={modalOpen} onChange={handleModalChange} modalContent={modalCon}  registerExist={registerExisting} lout={signout} user={user} />
            <LoginHero mobileCon={modalCon} registered={registered}  modalOpen={() => setModalOpen} />
            
            {showAlert&&
                <PageAlert message={alertMessage} type={alertType} setShowAlert={!setShowAlert} />
            } 
        </>
    )
      

      
      
}

export default Login