import React, { useEffect, useState, useRef } from "react";
import { css, cx } from '@emotion/css'
import styled from '@emotion/styled'
import ItemCard from "./item-card";
import MenuHeader from "./menu-header";



const TabBtn = styled.a`
    
    cursor: pointer;
    font-size: 0.8em;
    margin: 0 10px;
    padding: 5px 10px;
    border-radius: 25px;
    background-color: ${props => props.active ? '#fff !important': ''};
    // border: ${props => props.active ? '1px solid #333': '1px solid #eee'};
    color: ${props => props.active ? '#333 !important': ''};
    min-width: max-content;
    
`

const TabContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: no-wrap;
    flex-direction: row;
    margin: 0 0 20px 0;
    padding: 20px 0 20px 5vw;
    align-items: flex-start;
    justify-content: flex-start;
    
    overflow-x: auto;
    position: ${props => props.isSticky ? "fixed" : "block"};
    top: ${props => props.isSticky ? 0 : ""};
    -moz-box-shadow:    ${props => props.isSticky ? "1px 2px 2px 2px #ccc" : ""}
    -webkit-box-shadow: ${props => props.isSticky ? "2px 2px 2px 2px #ccc;" : ""}
    box-shadow: ${props => props.isSticky ? "2px 2px 2px 2px #ccc;" : ""}
    `


const TabCont = styled.div`
    width: 100%;
    display: ${props => props.active ? 'block' : 'none'};
    padding-top: ${props => props.isSticky ? "250px" : "10px"};
    padding-left:5vw;
    padding-right:5vw;
    padding-bottom: 100px;
`

const TabParent = styled.div`
    width: 100%;
    position: relative;

`

function TabsButtons(props) {

    const [tabState, setTabState] = useState(0);
    const handleTabClick = (index) => {
        
        setTabState(index);
        props.getData(index)
        
    }

    return (

        <TabContainer isSticky={props.isSticky ? true : false } 
            className={css`

            background-color: ${((props.data.brand == undefined) || (props.data.brand.secondary == undefined) || (props.data.brand.secondary == null)||(props.data.brand.secondary == "")) ?  props.data.brand == undefined ? '#d26355' : props.data.brand.background ? props.data.brand.background +'e0' : '#d26355' : props.data.brand.secondary};
            color: ${((props.data.brand == undefined)||(props.data.brand.textcolor == null)||(props.data.brand.textcolor == "")) ? '#fff' : props.data.brand.textcolor}; 
            
            `}
        >
        {props.data && props.data.menu.map((menu,catIndex) => {
            return (
            
                <TabBtn key={catIndex} onClick={() => handleTabClick(catIndex) } 
                active={tabState === catIndex ? true : false}
                    className={css`
                        background-color: ${((props.data.brand == undefined)||(props.data.brand.background == null)||(props.data.brand.background == "")) ? '#ed6a5a' : props.data.brand.background};
                        color: ${((props.data.brand == undefined)||(props.data.brand.textcolor == null)||(props.data.brand.textcolor == "")) ? '#fff' : props.data.brand.textcolor};          

                    `}
                >
                    {menu.category}
                </TabBtn>

            )
            })
        }

        </TabContainer>

    )
   

}




function Tabs(props) {

    const [tabState, setTabState] = useState(0);
    const [menuData, setMenuData] = useState('')
    const [isSticky, setIsSticky] = useState(false)
    const headerRef = useRef();
    const tabRef = useRef();



    const callbackFunction = (entires) => {
        const [entry] = entires
        if(entry.isIntersecting){
            setIsSticky(false)
        }
        
    }

    const callbackFunctionTab = (entires) => {
        const [entry] = entires
        if(!entry.isIntersecting){
            setIsSticky(true)
        }
    }

    const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.01
    }

    
    const optionsTab = {
        root: null,
        rootMargin: "0px",
        threshold: 1
    }

    useEffect(() => {
    
        const observer = new IntersectionObserver(callbackFunction, options)
        if(headerRef.current) observer.observe(headerRef.current)

        return () => {
            if(headerRef.current) observer.unobserve(headerRef.current)
        }
            

    }, [headerRef, options])


    useEffect(() => {
    
        const observer = new IntersectionObserver(callbackFunctionTab, options)
        if(tabRef.current) observer.observe(tabRef.current)

        return () => {
            if(tabRef.current) observer.unobserve(tabRef.current)
        }
            

    }, [tabRef, optionsTab])


    const getData = (x) => {
        
        setTabState(x)

    }

    useEffect(() => {
        console.log("Sticky", isSticky)
    },[isSticky])


    return(
        <>
            <MenuHeader data={props.data} ref={headerRef} />

            <TabParent ref={tabRef}>
                
                <TabsButtons data={props.data} getData={getData} isSticky={isSticky} />

            </TabParent>


            {props.data && props.data.menu.map((menu, catIndex) => {
                return (
                    <TabCont key={catIndex}  active={tabState === catIndex ? true : false}
                    > 
                    <small>
                        {menu.categoryDesc}
                    </small>
                    {menu.items.map((items, itemIndex) => {
                        return (
                            <div key={itemIndex} onClick={event => props.openDetails(catIndex, itemIndex, event)}>
                                <ItemCard key={itemIndex} title={items.title} imgurl={items.imgurl} desc={items.desc} price={items.price}  />
                            </div>
                        )
                        })
                    }

                    </TabCont>
                    
                )
            })
            }

            

        </>
    );
}

export default Tabs;