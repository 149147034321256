import React, { useEffect, useState, useRef } from "react";
import {Snackbar, TextField, IconButton, CircularProgress, Button } from '@material-ui/core'
import update from 'immutability-helper';
import "./../css/edit-item.css"
import { ContactSupportOutlined } from "@material-ui/icons";
import { useParams } from 'react-router-dom';
// import firebase from "firebase/app"
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage"
import ButtonTW from "./button-tw";
import ModalTW from "./utilities/modal-tw";
import Loading from "./utilities/loading";
import PageAlert from "./alerts/page-alert";


function EditItem(props) {

  let { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_EXT
  const [upimage, setUpImage] = useState(null) // checking if input is filled
  const [uploadActive, setUploadActive] = useState(true); // Tab states
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [imageTransaction, setImageTransaction] = useState("");
  
  var storage = getStorage();
  

  const [itemState, setItemState] = useState({
    imgurl: props.imgurl,
    title: props.title,
    desc: props.desc,
    price: props.price,
    categoryindex: props.cat,
    itemIndex: props.itm,
    category: props.category,
    categoryDesc: props.categoryDesc,
    name: props.name
  })

  const [editCat, setEditCat] = useState(props.editingcat)
  const [propsName, setPropsName] = useState('category')
  
  const [open, setOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState() // checking if image exits
  const [saveData, setSaved] = useState(false) // checking if save is clicked
  const [originalImage, setOriginalImage] = useState(); // initial image url
  const [showAlert, setShowAlert] = useState(false);
  const [hideAlert, setHideAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const inputImageRef = useRef();



  

  useEffect(() => {
    
    if(itemState.imgurl != ""){
      setImgUrl(itemState.imgurl)
      
      if(saveData) {
        props.data(itemState)
        setSaved(false)
      }
    }

  },[itemState])

  useEffect(() => {
    if(!props.saveSuccess) {
      setLoading(false)
    }
  },[props.saveSuccess])

  useEffect(() => {
    setLoading(false);
    if(itemState.imgurl != ""){
      setOriginalImage(itemState.imgurl)
    }
  },[])

  useEffect(() => {
    if(imageTransaction == "success") {
      save();
    }
  },[imageTransaction])

  function modalCancel() {
    setModalOpen(false)
    setImageTransaction("")
  
  }

  useEffect(() => {
    console.log(upimage)

    if(upimage != null) {
      setModalOpen(true);
      setImageTransaction("upload")
    }

  },[upimage])

function cancelImageUpload(){
  setUpImage(null)
  setImgUrl();
  setImageTransaction("");
  setModalOpen(false)
}


  
  useEffect(() => {
    if(props.name) {
      setPropsName('name')
    } else {
      setPropsName('category')
    }
  },[props])
  
  const handleItemChange = (event) => {
    
    const name = event.target.name
    
    setItemState({
      ...itemState,
      [name] : event.target.value
    })
    
  };

  const save = async () => {
    setLoading(true);
    setSaved(true);
    props.data(itemState);

    // // If new image was uploaded 
    // if(upimage) {
            
    //   console.log("There is new upload.")
      
    //   // If there was an image before. Delete it
    //   if(originalImage) {
        
    //     if (deleteImage(originalImage)) {
    //       upload();
    //     }
        

    //   } else {
        
    //     upload();

    //   }
       

    // } else {

      
    //   // If new image was NOT uploaded 

    //   if(originalImage && (imgUrl != "")) {
        
    //     if(deleteImage(originalImage)) {
    //       setOriginalImage("");
    //       props.data(itemState)
    //     }

    //   } else {

    //     

    //   }
      
    // }

    
    
    
  }



  const cancel = () => {
    props.cancel()
  }


  const changeImage = (event) => {

      setUpImage(event.target.files[0]);  
      setImgUrl(URL.createObjectURL(event.target.files[0]))
  }
  

  
  const upload = () => {
    setLoading(true);
    if(upimage.size > 2000000) {
      setLoading(false);
      // Show Error that file size is too large
      
      setAlertType("error");
      setAlertMessage("File size is too large,size should be less than 2 MB");
      setShowAlert(true);

    } else {
      modalCancel()
      var storageRef = ref(storage, "menu-item-images/" + id + "/" + props.cat + props.itm + upimage.name );
      // 'file' comes from the Blob or File API
      uploadBytesResumable(storageRef, upimage).then((snapshot) => {
        console.log('Uploaded file!');
        getDownloadURL(snapshot.ref).then(url => {

          setItemState({
            ...itemState,
            imgurl : url
          });

          setImageTransaction("success")
        });
      }).catch((error) => {
        setLoading(false);
        console.error('Upload failed', error);
        alert("We couldn't upload the image. Please try again later")
      });
    }
  }


  const removeImage = () => {
    
    //Open modal to confirm remove
    setModalOpen(true);
    setImageTransaction("remove");

  }

  const confirmRemoveImage = () => {
    modalCancel();
    setLoading(true);
    
    if(originalImage.includes("da-menus-15b3f")) {
      
      deleteImage(originalImage)

    } else {

      // Removing what is displayed to the user
      setImgUrl("")

      // Setting item Json state
      setItemState({
        ...itemState,
        imgurl : ""
      })

      // Clear the input field as well
      inputImageRef.current = ""

      
    }
  }

  const deleteImage = (img) => {    

    const deleteRef = ref(storage, img);
    
    deleteObject(deleteRef).then(() => {
      // File deleted successfully
      console.log("image deleted!")


      // Removing what is displayed to the user
      setImgUrl("")

      // Setting item Json state
      setItemState({
        ...itemState,
        imgurl : ""
      })

      // Clear the input field as well
      inputImageRef.current = ""
      setImageTransaction("success")

      
    }).catch((error) => {
      // Uh-oh, an error occurred!
      // alert("We couldn't delete the image. Please try again later")
      setImgUrl("")

      // Setting item Json state
      setItemState({
        ...itemState,
        imgurl : ""
      })

      // Clear the input field as well
      inputImageRef.current = ""

      setLoading(false)
    });

  }
 

  return (
    <div className="edit-modal global-padding-container">

      <div className="flex justify-end text-right pr-6 cursor-pointer" onClick={cancel} >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-dmprimary">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>

      {editCat ?
        <>
          <div className="edit-item">
            {/* <TextField 
            label={props.category? "Category": "Restaurants Name"}
            className="img-url"
            name={propsName} 
            maxLength="350" 
            onChange={event => handleItemChange(event)} 
            value={props.category? itemState.category: itemState.name}
            variant="filled"
            /> */}

            <div className="form-control">
                  <label className="label pl-0">
                      <span className="label-text text-dmbasetext">
                        {props.category? "Category": "Restaurants name"}
                      </span>
                  </label>
                      
                  <input 
                      type="text"
                      className="input input-bordered"
                      name={propsName} 
                      maxLength="350" 
                      onChange={event => handleItemChange(event)} 
                      value={props.category? itemState.category: itemState.name}
                      
                  />
            </div>

          

          {!props.name &&
            <>
              {/* <TextField 
              label="Category Description"
              className="img-url"
              name="categoryDesc"
              maxLength="350" 
              onChange={event => handleItemChange(event)} 
              value={itemState.categoryDesc}
              variant="filled"
              /> */}

              <div className="form-control">
                  <label className="label pl-0">
                      <span className="label-text text-dmbasetext">
                        Category description
                      </span>
                  </label>
                      
                  <input 
                      type="text"
                      className="input input-bordered"
                      name="categoryDesc"
                      maxLength="350" 
                      onChange={event => handleItemChange(event)} 
                      value={itemState.categoryDesc}
                      
                  />
              </div>
            </>
          }
          <div className="save-cancel-btn-group mt-10">
            <ButtonTW style="primary" onClick={save}>
                Save
            </ButtonTW>
            <ButtonTW style="secondary" onClick={cancel}>
                Cancel
            </ButtonTW>
          </div>

          </div>

          
        </>

      :

        <div className="edit-item">
        <h2>{itemState.title}</h2>

        
        

        

        {/* <TextField 
        label="Image URL"
        className="img-url"
        name="imgurl" 
        maxLength="1000"
        onChange={event => handleItemChange(event)} 
        value={itemState.imgurl}
        variant="filled"
        />
         */}
        
          <div className="bg-[#f0f0f0] rounded-[10px] p-4">
          {imgUrl ?
            
              <div>
                
                <img className="displayImage" src={imgUrl} /><br />
                <ButtonTW style="secondary" onClick={removeImage}>Remove Image</ButtonTW> 
              </div>
            

            :

            <>
              <div className="tabs tabs-boxed bg-transparent pl-0 mb-2">
                <a className={uploadActive ? "tab tab-active" : "tab"} onClick={() => (setUploadActive(true))}>Upload</a> 
                <a className={!uploadActive ? "tab tab-active" : "tab"} onClick={() => setUploadActive(false)}>Enter Image URL</a> 
              </div>
              <div className={uploadActive ? "bg-transparent imageArea px-4 py-2" : "hidden"}>
                  <label className="label pl-0" for="file-upload">
                      <span className="label-text text-dmwhite rounded-[10px] text-xs bg-dmbgdark px-4 py-2 cursor-pointer">Upload Image</span>
                  </label>
                  
                  <input 
                      className="mb-4 hidden"
                      type="file"
                      id="file-upload"
                      accept="image/*"
                      name="imgFile"
                      onChange={event => changeImage(event)}
                      ref={inputImageRef}
                  />
              </div>

              <div className={!uploadActive ? "form-control" : "hidden"}>
                <label className="label pl-0">
                    <span className="label-text text-dmbasetext">Image URL</span>
                </label>
                
                <input 
                    type="text"
                    className="input input-bordered"
                    name="imgurl" 
                    maxLength="1000"
                    onChange={event => handleItemChange(event)} 
                    value={itemState.imgurl}
                />
            </div>
            </>
            // <div>
            //   <div className="uploadButtonAreaLabel">Upload An Image</div>

            //   <input
            //     type="file"
            //     accept="image/*"
            //     name="imgFile"
            //     onChange={event => changeImage(event)}
            //     variant="filled"
            //     className="uploadButton"
            //     ref={inputImageRef}
            //   />
            // </div>
          }
          </div>
        

        {/* <TextField 
        label="Item Title" 
        name="title" 
        maxLength="150" 
        value={itemState.title}
        onChange={event => handleItemChange(event)} 
        variant="filled"
        /> */}

        <div className="form-control">
            <label className="label pl-0">
                <span className="label-text text-dmbasetext">
                  Item title
                </span>
            </label>
                
            <input 
                type="text"
                className="input input-bordered"
                name="title" 
                maxLength="150" 
                value={itemState.title}
                onChange={event => handleItemChange(event)} 
                
            />
        </div>

        <div className="form-control">
            <label className="label pl-0">
                <span className="label-text text-dmbasetext">
                  Item description
                </span>
            </label>
                
            <input 
                type="text"
                className="input input-bordered"
                name="desc" 
                maxLength="350" 
                onChange={event => handleItemChange(event)} 
                value={itemState.desc}
                
            />
        </div>


        <div className="form-control">
            <label className="label pl-0">
                <span className="label-text text-dmbasetext">
                  Item price
                </span>
            </label>
                
            <input 
                type="text"
                className="input input-bordered"
                name="price" 
                maxLength="50" 
                onChange={event => handleItemChange(event)} 
                value={itemState.price}
                
            />
        </div>


        {/* <TextField 
        label="Item Description" 
        name="desc" 
        maxLength="350" 
        onChange={event => handleItemChange(event)} 
        value={itemState.desc}
        variant="filled"
        /> 



        <TextField 
        label="Item Price" 
        name="price" 
        maxLength="350" 
        onChange={event => handleItemChange(event)} 
        value={itemState.price}
        variant="filled"
        />
        
        */}

        
        <div className="save-cancel-btn-group mt-10">
          
          <ButtonTW style="primary" onClick={save}>
              Save
          </ButtonTW>
          <ButtonTW style="secondary" onClick={cancel}>
              Cancel
          </ButtonTW>
        </div>

        {/* <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            open={open}
            autoHideDuration={3000}
            message="Image Uploaded"
            onClose={handleClose}
        /> */}

        </div>

      }

      {loading&&
        <Loading />
      }
      
      {imageTransaction == "remove" &&
        <ModalTW open={modalOpen} title="Remove Image" secCTA="Cancel" onClickSec={modalCancel} priCTA="Remove" onClickPri={() => confirmRemoveImage()} >
          Are you sure you want to remove this image? Image will be permanently removed. 
        </ModalTW>
      }

      {imageTransaction == "upload" &&
        <ModalTW open={modalOpen} title="Upload Image" secCTA="Cancel" onClickSec={cancelImageUpload} priCTA="Upload" onClickPri={upload}>
          Would you like to upload of the following image. <br />
          <p className="text-dmhelpertext">Max file size: 2 MB</p>
          {/* {upimage.size > 2000000 && 
            <>
            Max file size: 2 MB
            </>
          } */}
          <div className="mt-6 w-[100%] bg-dmsecondaryhov rounded-md flex items-center justify-center p-[10px]">
            <img src={imgUrl} className="rounded-md w-2/4" />
          </div>

        </ModalTW>
      }
      
      {showAlert&&
          <PageAlert message={alertMessage} type={alertType} hideAlert={() => setHideAlert(true)} />
      } 
      
      
    </div>
    
  );
}

export default EditItem;
