import React, { useEffect, useState } from "react";
import "./../App.css"
import PageAlert from "../components/alerts/page-alert";
import MainLayout from "../components/nav/main-layout";
import LayoutCard from "../components/dash/layout-card.js"
import { useUserContext } from "../context/user-context";
import { Edit } from "@material-ui/icons";
import { useParams,  useNavigate } from "react-router-dom";
import { TextField, CircularProgress, Button } from '@material-ui/core'
import ButtonTW from "../components/button-tw";
import styled from '@emotion/styled'
import Loading from "../components/utilities/loading";
import { PostData, getData } from "../components/utilities/data-handling";

const Layout = styled.div`
  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  

`

const Section = styled.div`
  width: 100%;
  margin-bottom: 30px;
`

function EditMenuLayout() {
    let resStatus = 0; 
    const { id } = useParams();
    const [formState, setFormState] = useState('');
    const [saved, setSaved] = useState(false);
    const [loginState, setLoginState] = useState(false)
    const apiUrl = process.env.REACT_APP_API_EXT
    const { user } = useUserContext();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(true);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    useEffect(() => {
        if(user) {
            getTheData();
        }
    },[])


    async function savedata() {
        let testdata;
        setLoading(true);
        PostData(formState, id).then((data) => {
            if(data == true){
                setLoading(false);
                setAlertType("success");
                setAlertMessage("Menu successfully updated.");
                setShowAlert(true);
            } else {
                setLoading(false);
                setAlertType("error");
                setAlertMessage("Error. Please try again later.");
                setShowAlert(true);
            }
            
         });        
        
        
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setShowAlert(false)
         }, 3000);
         
           return () => clearInterval(interval);
    },[alertMessage])
    

    function checkUD(x) {
        
        if(typeof x != 'undefined' || x != null) {
            return true
        }
        else return false
    }
    
    async function getTheData() {
        const data = await getData(id, user.uid);
        if(data) {
            setFormState(data);
        }

    }

    // const getData = (uuid) => {
      
    //     fetch(apiUrl + "/menu/" + id + "/" + uuid)
    //         .then(res => {
    //             resStatus = res.status;
    //             return res.json();
    //         })
    //         .then(
    //         (result) => {

    //             switch (resStatus) {
    //                 case 200:
    //                     setFormState(result)
    //                     break
                    
    //             }
    
    //         }
    //         )
    //         .catch((error) => {
    //         console.log("Error", error)
    //         switch(resStatus) {
    //             // case 404:
    //             //   alert('Your are not autorized to view edit this menu');
    //             //   break    
    //             case 500:
    //             console.log('Your are not autorized to view edit this menu')
                
    //             break
    //             default:
    //             console.log('Default Error')
    //             break
    //         }
    //     })
    
    // }

    const updateLayout = () => {

        
        setFormState({
          ...formState,
          layout: {
            ...formState.layout,
            tab : !formState.layout.tab
          }
    
        })
        

    }


    const handleColorChange = (event) => {
        
        const name = event.target.name
        
        setFormState({
        ...formState,
        brand: {
            ...formState.brand,
            [name] : event.target.value
        }

        })
        
    };


    const resetColors = () => {

        setFormState({
            ...formState,
            brand: {
            ...formState.brand,
            background: "",
            secondary: "",
            textcolor: ""

            }
    
        })
    }


    return(
        <MainLayout>
        {formState &&
        <>
            <Section className="detailsEditPageSec">
                
                <h3 className="text-3xl font-bold">Menu Colors</h3>
                <div className="text-md">Customize your menu page with your brand colors</div>

                <div className="form-control">
                    <label className="label pl-0">
                        <span className="label-text text-dmbasetext">Primary Brand Color</span>
                    </label>
                    <div className="flex flex-row items-center gap-2 justify-start mb-4">
                        <input 
                            value={checkUD(formState.brand.background) ? formState.brand.background : null }
                            onChange={(event) => handleColorChange(event)} 
                            type="color"
                            name="background" 
                            className="input p-1"
                        />
                        <input 
                            type="text"
                            className="input input-bordered"
                            value={checkUD(formState.brand.background) ? formState.brand.background : null}
                            name="background" 
                            readOnly 
                        />
                    </div>
                </div>

                <div className="form-control">
                    <label className="label pl-0">
                        <span className="label-text text-dmbasetext">Secondary Brand Color (optional)</span>
                    </label>
                    <div className="flex flex-row items-center gap-2 justify-start mb-4">
                        <input 
                            value={checkUD(formState.brand.secondary) ? formState.brand.secondary : null }
                            onChange={event => handleColorChange(event)} 
                            type="color"
                            name="secondary" 
                            className="input p-1"
                        />
                        <input 
                            type="text"
                            className="input input-bordered"
                            value={checkUD(formState.brand.secondary) ? formState.brand.secondary : null }
                            name="secondary" 
                            readOnly 
                        />
                    </div>

                </div>
                
                <div className="form-control">
                    <label className="label pl-0">
                        <span className="label-text text-dmbasetext">Text Color</span>
                    </label>
                    <div className="flex flex-row items-center gap-2 justify-start">
                        <input 
                            value={checkUD(formState.brand.textcolor) ? formState.brand.textcolor : null }
                            onChange={event => handleColorChange(event)} 
                            type="color"
                            name="textcolor" 
                            className="input p-1"
                        />
                        <input 
                            type="text"
                            className="input input-bordered"
                            value={checkUD(formState.brand.textcolor) ? formState.brand.textcolor : null }
                            name="textcolor" 
                            readOnly
                        />
                    </div>
                </div>

                {/* 

                <TextField 
                label="Header Color" 
                name="background" 
                value={formState.brand.background}
                onChange={event => handleColorChange(event)} 
                type="color"
                variant="filled"
                /> 

                <TextField 
                label="Category Color" 
                name="secondary" 
                value={formState.brand.secondary}
                onChange={event => handleColorChange(event)} 
                type="color"
                variant="filled"
                />

                */}
                

                {/* <TextField 
                label="Text Color" 
                name="textcolor" 
                value={formState.brand.textcolor}
                onChange={event => handleColorChange(event)} 
                type="color"
                variant="filled"
                /> */}
                
                <br />
                <a onClick={resetColors} >
                    Reset to default colors
                </a> 

            </Section>
        

            <div className="border-[0.75px] my-6 border-[#ddd]"></div>
           
                <h3 className="text-3xl font-bold">Menu Layout</h3>
                <div className="text-md mb-8">Choose between tab and list layout</div>

                <Layout>
                    <LayoutCard data={formState.layout.tab} updateLayout={() => updateLayout()} />
                </Layout>
        
        

            <div className="border-[0.75px] my-10 border-[#ddd]"></div>
            <div className="save-cancel-btn-group mt-10">
                <ButtonTW style="primary" onClick={savedata}>
                    Save
                </ButtonTW>
                <ButtonTW style="secondary">
                    Cancel
                </ButtonTW>
            </div>
            
            
            {showAlert&&
                <PageAlert message={alertMessage} type={alertType} />
            }               
                
            
            
        </>

        }

        {loading && 
           
           
            <Loading />
        
        }

        
            
        

       

        
        </MainLayout>
    )
}

export default EditMenuLayout