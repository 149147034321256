import React from "react";
// import Home from "./pages/home.js"

// import EditAlerts from "./pages/edit-alerts.js"
import Login from "./pages/login.js"
import Dashboard from "./pages/dash.js"
import SignUp from "./pages/sign-up.js";
import Edit from "./pages/edit.js"
import EditBusInfo from "./pages/editBusInfo"
import EditMenuLayout from "./pages/editMenuLayout.js";
import EditCustomerAlerts from "./pages/editCustomerAlerts.js"
import Rewards from "./pages/rewards.js";
// import Pricing from "./pages/pricing"
// import RegisterExisting from "./pages/registerExisting.js";

// import Register from "./pages/register.js";
import Layout from "./components/layout.js";
import RequireAuth from "./components/require-auth.js";
import { Routes, Route, HashRouter } from "react-router-dom";
import { useUserContext } from "./context/user-context.js";
import ForgotPassword from "./pages/forgotPassword/forgotPass.js";
import ResetPassword from "./pages/forgotPassword/resetPass.js";
import Reg from "./pages/reg-verify/reg.js";
import MenuPage from "./pages/menuPage.js";




function App() {
  
  const { loading, error, user } = useUserContext();

  return (
  
            
            <Routes>
              <Route path="/" element={<Layout />}>
                {/* Public Routes */}
                <Route path="/" element={<Login />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="forgot" element={<ForgotPassword />} />
                <Route path="verify" element={<ResetPassword />} />
                <Route path="reg" element={<Reg />} />
                <Route path="menu/:id" element={<MenuPage />} />


                {/* Private Routes */}
                <Route element={<RequireAuth />}>
                  <Route path="rewards/:id" element={<Rewards />} />
                  <Route path="editCustomerAlerts/:id" element={<EditCustomerAlerts />} />
                  <Route path="editMenuLayout/:id" element={<EditMenuLayout />} />
                  <Route path="editBus/:id" element={<EditBusInfo />} />
                  <Route path="edit/:id" element={<Edit />} />
                  <Route path="dashboard/:id" element={<Dashboard />} />
                  <Route path="/" element={<Login />} />
                </Route>
              </Route>
            </Routes>

  );
}

export default App;
