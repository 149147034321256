import React, { useEffect, useState, useRef } from "react";
import "./../App.css"
import "firebase/auth"
import update from 'immutability-helper';
import { TextField, CircularProgress, Button } from '@material-ui/core'
import { RemoveCircle, AddCircle, WarningOutlined, ArrowUpward, ArrowDownward, EditSharp, Delete, FileCopyRounded  } from '@material-ui/icons';
import { Helmet } from "react-helmet";
import { Link , useNavigate, useParams } from 'react-router-dom';
import ButtonTW from "../components/button-tw";
import { css, cx } from '@emotion/css'
import styled from '@emotion/styled'
import MainLayout from "../components/nav/main-layout";
import { useUserContext } from "../context/user-context";
import Loading from "../components/utilities/loading";
import PageAlert from "../components/alerts/page-alert";
import { PostData, checkUD, getData } from "../components/utilities/data-handling";

let resStatus = 0; 

const H3 = styled.h3`
  font-size: 1.4em;
  
`

const H4 = styled.h4`
  font-size: 1.1em;

  margin-top: 0px;
  
`

const Section = styled.div`
  width: 100%;
  margin-bottom: 30px;
`


function EditBusInfo() {

    let { id } = useParams();
    
    const [formState, setFormState] = useState('');

    const [noAccess, setNoAccess] = useState(false);
    const [saved, setSaved] = useState(false);
    const [loginState, setLoginState] = useState(false)
    const apiUrl = process.env.REACT_APP_API_EXT
    const { user } = useUserContext();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(true);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
   
    
    useEffect(() => {
    
      if (user) {
        setLoading(false);
        getTheData();
      } 

    }, []);



    async function getTheData() {
        const data = await getData(id, user.uid);
        if(data) {
            setFormState(data);
        }

    }



const cancel = () => {
    navigate(`/dashboard/${id}`);
}



const handleChange = (event) => {
    
    const name = event.target.name
    
    setFormState({
      ...formState,
      [name] : event.target.value
    })
    
};

const handleBDetailsChange = (event) => {
    
    const name = event.target.name
    
    setFormState({
      ...formState,
      businessDetails: {
        ...formState.businessDetails,
        [name] : event.target.value
      }

    })
    
};


// const getData = (uuid) => {
    
      
//     fetch(apiUrl + "/menu/" + id + "/" + uuid)
//         .then(res => {
//             resStatus = res.status;
//             return res.json();
//         })
//         .then(
//         (result) => {

//             switch (resStatus) {
//                 case 200:
                    
//                     setFormState(result)

//                     break
                
//             }

//         }
//         )
//         .catch((error) => {
//         console.log("Error", error)
//         switch(resStatus) {
//             // case 404:
//             //   alert('Your are not autorized to view edit this menu');
//             //   break    
//             case 500:
//             console.log('Your are not autorized to view edit this menu')
//             setNoAccess(true)
//             break
//             default:
//             console.log('Default Error')
//             break
//         }
//     })

// }

// POST DATA
async function savedata() {
    let testdata;
    setLoading(true);
    PostData(formState, id).then((data) => {
        if(data == true){
            setLoading(false);
            setAlertType("success");
            setAlertMessage("Info successfully updated.");
            setShowAlert(true);
        } else {
            setLoading(false);
            setAlertType("error");
            setAlertMessage("Error. Please try again later.");
            setShowAlert(true);
        }
        
     });        
    
    
}


return (
    <MainLayout>
        {formState &&
            <div>
                <Helmet>
                    <title>Business Information - {formState.name}</title>
                </Helmet>

            
                
                <h3 className="text-3xl font-bold">Business Information</h3>
                <div className="text-md">Edit information about your business </div>

                <Section className="mt-4">

                    <div className="form-control">
                        <label className="label pl-0">
                            <span className="label-text text-dmbasetext">Business Name</span>
                        </label>    
                        <input 
                            type="text"
                            className="input input-bordered"
                            value={formState.name}
                            onChange={event => handleChange(event)} 
                            name="name" 
                        />
                        
                    </div>

                    {/* <H4>Business Name</H4>
                    <TextField 
                    label="Business Name" 
                    name="name" 
                    maxLength="500" 
                    value={formState.name}
                    onChange={event => handleChange(event)} 
                    variant="filled"
                    /> */}
                </Section>
                
                
                
                <Section className="detailsEditPageSec">

                <div className="form-control">
                    <label className="label pl-0">
                        <span className="label-text text-dmbasetext">Address</span>
                    </label>    
                    <input 
                        type="text"
                        className="input input-bordered"
                        value={
                            checkUD(formState.businessDetails) ?
                                checkUD(formState.businessDetails.address) ? formState.businessDetails.address : "" 
                                :
                                ""
                        }
                        onChange={event => handleBDetailsChange(event)} 
                        name="address" 
                    />
                    
                </div>

                <div className="form-control">
                    <label className="label pl-0">
                        <span className="label-text text-dmbasetext">Phone</span>
                    </label>    
                    <input 
                        type="tel"
                        maxLength={30}
                        className="input input-bordered"
                        value={
                            checkUD(formState.businessDetails) ?
                            checkUD(formState.businessDetails.tel) ? formState.businessDetails.tel : "" 
                            : 
                            ""
                        }
                        onChange={event => handleBDetailsChange(event)} 
                        name="tel" 
                    />
                    
                </div>
                    
                    {/* <H4>Details</H4>

                    <TextField 
                    label="Business Address" 
                    name="address" 
                    maxLength="500" 
                    value={formState.businessDetails.address}
                    onChange={event => handleBDetailsChange(event)} 
                    variant="filled"
                    />

                    <TextField 
                    label="Business Phone" 
                    name="tel" 
                    maxLength="30" 
                    type="tel"
                    value={formState.businessDetails.tel}
                    onChange={event => handleBDetailsChange(event)} 
                    variant="filled"
                    /> */}
                    
                </Section>


                {/* 
                // <Section className="detailsEditPageSec">
                    
                //     <H4>Details</H4>

                //     <TextField 
                //     label="Business Address" 
                //     name="address" 
                //     maxLength="500" 
                //     onChange={event => handleBDetailsChange(event)} 
                //     variant="filled"
                //     />

                //     <TextField 
                //     label="Business Phone" 
                //     name="tel" 
                //     maxLength="30" 
                //     type="tel"
                //     onChange={event => handleBDetailsChange(event)} 
                //     variant="filled"
                //     />
                    
                // </Section> */}

                

                

                
                
                

                
                {/* <div className="save-cancel-btn-group">
                    <Button variant="contained" color="primary" onClick={save}>Save</Button>
                    <Button variant="contained" color="secondary" onClick={cancel}>Cancel</Button>
                </div> */}

                <div className="border-[0.75px] my-10 border-[#ddd]"></div>
                <div className="save-cancel-btn-group mt-10">
                    <ButtonTW style="primary" onClick={savedata}>
                        Save
                    </ButtonTW>
                    <ButtonTW style="secondary">
                        Cancel
                    </ButtonTW>
                </div>


                {showAlert&&
                    <PageAlert message={alertMessage} type={alertType} />
                } 
            </div>
        }

        {loading && 
           
           
           <Loading />
       
       }
    </MainLayout>
)

    

}

export default EditBusInfo;