import React, { Children, useEffect, useState } from "react";

function ButtonTW(props) {
  
  return (
    <>
      {props.style=="primary"&&
        <button className="btn normal-case text-[#fff] bg-dmprimary hover:bg-dmprimary/90" onClick={props.onClick}>
          {props.children}
        </button>
      } 
      {props.style=="secondary"&&
        <button className="btn normal-case text-[#222] hover:text-[#333] border-0 bg-dmsecondary hover:bg-dmsecondaryhov" onClick={props.onClick}>
          {props.children}
        </button>
      } 
      {props.style=="tertiary"&&
        <button className="btn normal-case text-dmprimary hover:text-[#333] border-1 border-dmprimary bg-[#fff] hover:bg-dmsecondary" onClick={props.onClick}>
          {props.children}
        </button>
      } 

      {props.style=="box"&&
        <button className="btn text- flex flex-col justify-center w-[50%] h-[100%] normal-case text-dmprimary hover:text-[#333] border-1 border-dmprimary bg-[#fff] hover:bg-dmsecondary" onClick={props.onClick}>
          {props.children}
        </button>
      } 
    </>   
  );
}

export default ButtonTW;
