import React, { useEffect, useState } from "react";
import { css, cx } from '@emotion/css'
import styled from '@emotion/styled'
import ItemCard from "./item-card";




function ListView(props) {
   

    useEffect(() => {
        console.log(props.data)
    },[props.data])


    return(
        <>
            
            {props.data && props.data.menu.map((menu, ixd) => {
                return (
                    <div key={ixd}>
                    
                        <div className="global-padding-container cat-background"
                            className={css`

                                background-color: ${((props.data.brand == undefined) || (props.data.brand.secondary == undefined) || (props.data.brand.secondary == null)||(props.data.brand.secondary == "")) ?  props.data.brand == undefined ? '#d26355' : props.data.brand.background ? props.data.brand.background +'e0' : '#d26355' : props.data.brand.secondary};
                                color: ${((props.data.brand == undefined)||(props.data.brand.textcolor == null)||(props.data.brand.textcolor == "")) ? '#fff' : props.data.brand.textcolor};
                                padding: 20px 5vw;
                                word-break: keep-all;
                                width: auto;     
                            
                            `}
                        
                        >
                            <h2
                                className={css`
                                color: ${((props.data.brand == undefined)||(props.data.brand.textcolor == null)||(props.data.brand.textcolor == "")) ? '#fff' : props.data.brand.textcolor};
                                `}
                            >
                                {menu.category}
                            </h2> 
                            {menu.categoryDesc &&
                                
                            
                                    <div className={css` 
                                        
                                        font-size: 13px;
                                    `}
                                    >
                                        {menu.categoryDesc.replace(/\\n/g,'\n')}
                                        
                                    </div>
                                    
                                
                            }
                        </div>
                        <div  className={css`

                            display: ${((menu.items == undefined) || (menu.items == null)||(menu.items == "")) ?  "none" : "block"};
                            padding: 20px 5vw;
                            
                        `}> 
                            {
                                menu.items.map((item, idx) => {
                                    return(
                                        <div onClick={event => props.openDetails(ixd, idx, event)}>
                                            <ItemCard key={idx} title={item.title} imgurl={item.imgurl} desc={item.desc} price={item.price}  />
                                        </div>
                                    )
                                })
                            } 
                        </div>
                        
                    </div>
                    )
                    }
                )
            }


        </>
    );
}

export default ListView;