import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useUserContext } from "../context/user-context";

const RequireAuth = () => {
    
    const { user } = useUserContext();
    const location = useLocation();

    return (
        user 
            ? <Outlet />
            : <Navigate to="/" state={{ from: location }} replace />
        
    );

}

export default RequireAuth