

export function PostData (formData, id) {
    
    let resStatus = 0; 
    const apiUrl = process.env.REACT_APP_API_EXT
    

    const requestOptions = {
      method: 'POST',
      headers: { 
          'Content-Type': 'application/json',
      
      },
          body: JSON.stringify(formData)
      };
      

      return fetch( apiUrl + "/menu/" + id, requestOptions)
      .then(response => {
        if(response.ok){
          return true;
        }
      })
      .catch((err) => {
         return err;
      }) 


      // .then(
      //     (result) => {
      //       switch (resStatus) {
      //         case 200:
      //             console.log("success")
      //             break;
                  
      //       }
      //       console.log(resStatus);
      //       return resStatus;  
      //     },

        
      // )
      // .catch((error) => {
      //   switch(resStatus){
      //     case 500:
      //       break
            
      //     default:
      //       break
            
      //   }
      //   return false;
      // })

   
    
}


export function checkUD(x) {
        
    if(typeof x != 'undefined' || x != null) {
        return true
    }
    else return false
}

export function getData(id, uuid) {
    
    const apiUrl = process.env.REACT_APP_API_EXT
    return fetch(apiUrl + "/menu/" + id + "/" + uuid)
    .then(response => {
      return response.json().then((data) => {
        if(data) {
          return data;
        }
      }) 
    })
    .catch((err) => {
       return err;
    }) 



}
