import React, { useEffect, useState } from "react";
import { css, cx } from '@emotion/css'



function ClientCarousel(props) {
    const color = '#ed6a5a'

    return (
        
        <a href={props.url} target="_blank"
            className={css`
            padding: 20px;
            background-color: #ddd;
            border-radius: 10px;
            min-height: 100%;
            margin: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            
            &:hover {
            background-color: ${color};
            }
            
        `}
        >
            <img src={props.imgsrc} 
                className={css`
                    width: 150px;
                    height: auto;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    opacity: 0.9;

                    &:hover {
                        opacity: 1;
                    }
                `}
            
            />
        </a>

    );

}

export default ClientCarousel;