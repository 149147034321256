
function Loading() {

    return(
        
        <div className="w-[100%] h-screen bg-dmsecondary bg-opacity-80 absolute flex justify-center items-center !top-0 !left-0">
            <div className="align-middle text-center">
                <span className="loading loading-ring !bg-dmprimary w-[400px]"></span>
            </div>
        </div>
    )

}

export default Loading;