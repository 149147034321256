import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"


const firebaseConfig = {
  apiKey: "AIzaSyB7pRsqHUkQWJc0MU0KHrJoCjXnD7G81Hs",
  authDomain: "da-menus-15b3f.firebaseapp.com",
  projectId: "da-menus-15b3f",
  storageBucket: "da-menus-15b3f.appspot.com",
  messagingSenderId: "462159770997",
  appId: "1:462159770997:web:5d8083729e9ad14c450f3c",
  measurementId: "G-7X5GJN1QTW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);


// const firebaseConfig = {
//   apiKey: "AIzaSyB7pRsqHUkQWJc0MU0KHrJoCjXnD7G81Hs",
//   authDomain: "da-menus.com",
//   databaseURL: "https://da-menus-15b3f.firebaseio.com",
//   projectId: "da-menus-15b3f",
//   storageBucket: "da-menus-15b3f.appspot.com",
//   messagingSenderId: "462159770997",
//   appId: "1:462159770997:web:5d8083729e9ad14c450f3c",
//   measurementId: "G-7X5GJN1QTW"
// };


// firebase.initializeApp(firebaseConfig);
// var storage = firebase.storage();

// export default firebase;
