import { React, useEffect, useState } from "react";
import { useUserContext } from "../../context/user-context";
import "../../App.css"
import HeaderLogo from "./../../img/logos/logo-alt.png"
import { Link, useParams, useLocation } from "react-router-dom";

const MainLayout = ({children}) => {

    const { user, logoutUser } = useUserContext();
    const location = useLocation();
    const { id } = useParams();

    return(
         <div className="drawer md:drawer-open">
                
                
                <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
                
                
                <div className="drawer-content">
                    <div className="w-full bg-dmbgdarkSec px-6 py-4  md:hidden">
                        <label htmlFor="my-drawer-2" className="text-[#fff] drawer-button">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </label>
                    </div>
                    
                    <div className="global-padding-container relative h-[100%]"> 
                        {children}
                    </div>
                </div> 

                <div className="drawer-side fixed">
                    <label htmlFor="my-drawer-2" className="drawer-overlay"></label> 
                    <div className="menu p-4 pt-8 pb-0 w-80 h-full justify-between bg-dmbgdark text-base-content">
                        {/* Sidebar content here */}
                    
                        <ul>
                            
                            <li>
                                <Link to={"/dashboard/" + id} className={location.pathname.includes("/dashboard/") ? "active": "text-dmwhite"} >
                                    Dashboard
                                </Link>
                            </li>
                            <li>
                                <Link to={"/edit/" + id}   className={location.pathname.includes("/edit/") ? "active": "text-dmwhite"}>
                                    Menu
                                </Link>
                            </li>
                            <li>
                                <Link to={"/editMenuLayout/" + id}   className={location.pathname.includes("/editMenuLayout/") ? "active": "text-dmwhite"}>
                                    Menu Colors & Layout
                                </Link>
                            </li>
                            <li>
                                <Link to={"/editBus/" + id} className={location.pathname.includes("/editBus/") ? "active": "text-dmwhite"}>
                                    Business Information
                                </Link>
                            </li>
                            
                            <li>
                                <Link to={"/editCustomerAlerts/" + id} className={location.pathname.includes("/editCustomerAlerts/") ? "active": "text-dmwhite"}>
                                    Customer Alerts
                                </Link>
                            </li>

                            <li>
                                <Link to={"/menu/" + id} target="_blank" rel="noopener noreferrer" className={location.pathname.includes("/menu/") ? "active": "text-dmwhite"}>
                                    View menu 
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                    </svg>

                                </Link>
                            </li>
                            
                            
                            {/* <li>
                                <Link to={"/rewards/" + id}  className={location.pathname.includes("/rewards/") ? "active": "text-dmwhite"}>
                                    Rewards
                                </Link>
                            </li> */}
                        </ul>

                        <div className="flex flex-row items-start justify-between p-4 -mx-4  bg-dmbgdarkSec">
                            <div className="flex flex-col justify-start items-start gap-1 text-dmwhite">
                                <b className="text-xl">{user.displayName}</b>
                                <Link href="javascript:void(0)" onClick={logoutUser} className="text-dmwhite underline">
                                    <small>Sign Out</small>
                                </Link>
                            </div>
                            <div className="avatar">
                                <div className="w-12 mask mask-squircle bg-dmbgdarkTer">
                                    {user.photoURL? 
                                        <img src={user.photoURL} className="scale-50" />
                                        :
                                        <img src={HeaderLogo} />
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
            
                </div>
        </div>
    );
}

export default MainLayout;