import React, { useEffect, useState } from "react";
import styled from '@emotion/styled'
import HeroImage from "../../img/hero.png"
import Brand from "../../img/features/brand.png"
import { mq } from "../utilities/media-queries.js";

const HeroArea = styled.div`
    width: 100%;
    height: auto;
    padding: 50px 5vw 0 5vw;
    background-color: #222;
    color: #fff;
    display: flex;
    flex-direction: column;
    

    ${mq[1]} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 50px;
    },

    & > div {
        width: 100%;
        word-break: keep-all ;
        text-align: center;
        

        ${mq[1]} {
            width: 48%;
            text-align: right;
        }
    }


    
`

const H1 = styled.h1`
    font-size: 2em;
    line-height: 0.5em;
    margin-bottom: 20px;

    ${mq[1]} {
        font-size: 2.7em;
        line-height: 1.1em;
    }
`

const Image = styled.div`
    max-width: 100%;
    display: block;
    margin: auto;

    & > img {
        max-width: 100%;
        display: block;
        margin: auto;
    }

    ${mq[1]} {
        width: 48%;
        text-align: left;
    }
`
const Desc = styled.div`
  
  margin: 10px 0;
  font-size: 1em;
  word-break: keep-all;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;


  ${mq[1]} {
    text-align: right;
    font-size: 1.3em;
  }
`

const Buttons = styled.div`
    width: 100%;
    margin: 30px 0;

    & > button {
        width: 100%;

        ${mq[1]} {
            width: auto;
        }
    } 
`

function LoginBrand(props) {

    return(
        <HeroArea>
            <div>

                <H1>
                    Design it your way
                </H1>

                <Desc>
                    You can customize the menu layout (tab / list) and colors to your liking.
                </Desc>

            

            </div>

            <Image>
                <img src={Brand} />
            </Image>

        </HeroArea>
    );
}

export default LoginBrand;