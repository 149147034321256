import React, { useEffect, useState, useRef } from "react";
import styled from '@emotion/styled'
import HeaderLogo from "./../../img/logos/logo-alt.png"
import { mq } from "../utilities/media-queries.js";
import ButtonTW from "../button-tw";
import { FaGoogle } from "react-icons/fa";
import { useNavigate, Link } from 'react-router-dom';
import { useUserContext } from "../../context/user-context";

const HeroArea = styled.div`
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    padding: 50px 5vw;
    background-color: #4b6bfb;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: scroll;




    
`

const Logo = styled.div`
    font-size: 2em;    
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > img {
        width: 120px;
        height: 120px;
    }
`

const CardBox = styled.div`
    
    background-color: #fff;
    width: 95%;
    max-width: 500px;
    padding: 20px;
    border-radius: 15px;
    color: #111;
    
    height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;


`

const Desc = styled.div`
  font-size: 1em;
  word-break: keep-all;
  width: 100%;
  text-align: center;


  ${mq[1]} {
    text-align: center;
  }
`





function LoginHero(props) {
    
    const navigate = useNavigate()
    const { signInUserGoogle, user, logoutUser, signInEmailPassUser } = useUserContext();
    const passref = useRef();
    const emailref = useRef();
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [showPass, setShowPass] = useState(true)
    const [errors, setErrors]  = useState(
        {
            emailError : "",
            passError : ""
            
        }
    )

    useEffect(() => {
        if (props.registered && (props.modalCon != ("not-reg" || "with-email"))) {
            props.modalOpen(true)
        }     
                    
    },[])

    const resetErrors = (string) => {
        // setIsSubmitted(false);
        switch(string) {
            case emailref:
                setErrors(prevState => ({
                    ...prevState,
                    emailError : ""
                }));
            break

            case passref:
                setErrors(prevState => ({
                    ...prevState,
                    passError : ""
                }));
            break

        }

        
    }

    function isEmpty(a) {
        if(a.trim() === "") {
            return true;
        }
        else return false;
    }

    const validateEmail = (inpemail) => {
        return String(inpemail)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    function Submit(e) {
        e.preventDefault();
        setIsSubmitted(true);
        const email = emailref.current.value;
        const password = passref.current.value;

        switch(true) {
            case isEmpty(email):
                setErrors(prevState => ({
                    ...prevState,
                    emailError: "Please enter an e-mail address"
                }));
            break;

            case isEmpty(password):
                setErrors(prevState => ({
                    ...prevState,
                    passError: "Please enter a password"
                }));
            break;
            
            default:
                if(!validateEmail(email)){
                    setErrors(prevState => ({
                        ...prevState,
                        emailError: "Please enter a valid e-mail address"
                    }));
                } else {
                    //lets sign in
                    signInEmailPassUser(email, password)
                }
        }



    }
    
    

    return(
        <HeroArea>
            <div className="sm:w-full md:w-[70%] mb-10">
                <Logo>
                    <img src={HeaderLogo} /> 
                    Da Menus Admin
                </Logo>
                

                {!user ? 
                    <Desc>  
                    Sign In to your menu management portal
                    </Desc>
                    :
                    <Desc>
                        Welcome back {user.displayName}
                    </Desc>
                }

            </div>
            
            {!user ? 
                    <CardBox>
                              
                        <ButtonTW style="tertiary"
                            onClick={signInUserGoogle}
                        >
                            <FaGoogle /> Continue with Google
                        </ButtonTW>

                        <div className="border-[0.75px] my-6 border-[#ddd]"></div>
                        
                        <div className="form-control w-full mb-4">
                            <label className="label">
                                <span className="label-text">E-mail address</span>
                            
                            </label>
                            <input type="text" ref={emailref} className="input input-bordered w-full" required onFocus={() => resetErrors(emailref)} />
                            <label className={isSubmitted && !isEmpty(errors.emailError) ? "label" : "label hidden"}>
                                <span className="label-text text-error">{errors.emailError}</span>
                            </label>
                        
                        </div>
                        
                        <div className="form-control w-full mb-4">
                            <label className="label">
                                <span className="label-text">Password</span>
                                <a className="link link-primary text-sm" onClick={() => setShowPass(!showPass)}>{showPass ? "Show" : "Hide"}</a>
                            </label>
                            <input type={showPass ? "password" : "text"} className="input input-bordered w-full" ref={passref} required onFocus={() => resetErrors(passref)} />
                            <label className={isSubmitted && !isEmpty(errors.passError) ? "label" : "label hidden"}>
                                <span className="label-text text-error ">{errors.passError}</span>
                            </label>
                            <label className="label">
                                <Link to="/forgot" className="link link-primary text-sm">Forgot password</Link>
                            </label>
                            
                        </div>  
                        
                    
                    
                        <ButtonTW style="primary" onClick={Submit}>
                            Sign in
                        </ButtonTW>

                        <div className="border-[0.75px] my-6 border-[#ddd]"></div>

                        <div>
                            Dont have an account? <a className="link link-primary pl-4" onClick={() => {navigate('/signup') }}>Sign Up</a>
                        </div>
                            

                            
                    </CardBox>

                :

                    <CardBox>   

                        <ButtonTW style="primary" onClick={props.modalOpen(true)}>
                            Your menus
                        </ButtonTW>

                        <div className="h-4"></div>

                        <ButtonTW style="secondary" onClick={logoutUser}>
                            Logout
                        </ButtonTW>

                    </CardBox>

            }
            


        </HeroArea>
    );
}

export default LoginHero;