import React, { useEffect, useState } from "react";
import ItemCard from "./../components/item-card.js"
import "./../App.css"
import { useParams } from "react-router-dom";
import ItemDetails from './../components/item-details.js'
// import CallServer from './../components/call-server.js'
import MenuHeader from "../components/menu-header.js";
import ListView from "../components/list-view.js";
import Ads from './../components/ads.js'
import Tabs from './../components/tabs.js'
import {Helmet} from "react-helmet";
import { CircularProgress } from '@material-ui/core'
import { css, cx } from '@emotion/css'
import { Block } from "@material-ui/icons";
import styled from '@emotion/styled'



let resStatus = 0; 

function Home() {

    const [formState, setFormState] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [detailData, setDetailData] = useState('')
    const [layoutTab, setLayoutTab] = useState(false)
    const [backgroundColor, setBackgroundColor] = useState('')
    const [catColor, setCatColor] = useState('')
    const [textColor, setTextColor] = useState('')
    const [brand, setBrand] = useState([])
    const [readMore,setReadMore] = useState(false);
    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_API_EXT)
    const [businessClosed, setBusinessClosed] = useState(false)
    let { id } = useParams();


    

    function handleModalChange() {
        setModalOpen(!modalOpen)
    }

    function clickReadMore() {
        setReadMore(!readMore)
    }

    useEffect(() => {

        if(modalOpen) {
            document.body.classList.add('scroll-hidden');
            console.log("opensdas")
        } else {
            document.body.classList.remove('scroll-hidden');
        }
        
    }, [modalOpen])

    

    function openDetails(ixd,idx) {
        setModalOpen(true)
        const dataNode = formState.menu[ixd].items[idx];
        const color = formState.brand.background;
        const text = formState.brand.textcolor;
        setBrand({
            background: color,
            textcolor: text
        })
        setDetailData(dataNode);
    }   


    useEffect(() => {

        const currentDate = new Date();
        
        const openTime = new Date (currentDate.getTime())
        openTime.setHours("06")
        openTime.setMinutes("00")
        openTime.setSeconds("00")

        const closeTime = new Date (currentDate.getTime())
        closeTime.setHours("23")
        closeTime.setMinutes("00")
        closeTime.setSeconds("00")

        if(openTime < currentDate && closeTime > currentDate) {
            setBusinessClosed(false)
            console.log("Business Open")
            
        } else {
            setBusinessClosed(true)
            console.log("Business Closed")
            
        }


    }, [])

    // useEffect(() => {
    //     if(formState) {
            
    //         if((typeof(formState.brand) !== undefined) || (formState.brand !== "") || (formState.brand !== null)) {
                
    //             if ((typeof(formState.brand.background) === undefined) || (formState.brand.background === "") || (formState.brand.background === null)) 
    //             {
                    
    //                 setBackgroundColor('#ed6a5a')    
                
    //             } else {  

    //                 setBackgroundColor(formState.brand.background)    
    //             }

    //             if ((typeof(formState.brand.secondary) === undefined) || (formState.brand.secondary === "") || (formState.brand.secondary === null)) 
    //             {
    //                 if ((typeof(formState.brand.background) === undefined) || (formState.brand.background === "") || (formState.brand.background === null)) 
    //                 {
                        
    //                     setCatColor('#d26355');
                    
    //                 } else {  

    //                     setCatColor(formState.brand.background +'e0')  
    //                 }  
                
    //             } else {  

    //                 setCatColor(formState.brand.secondary)    
    //             }

    //         }
            
    
    //     } 

        
    // },[formState])

    useEffect(() => {
        console.log(backgroundColor)
    },[backgroundColor])


    useEffect(() => {

        // window.location.href = 'http://localhost:3000/menu/' + id
    
    fetch(apiUrl + "/menu/" + id )
        .then(res => {
            resStatus = res.status;
            return res.json();
        })
        .then(
        (result) => {

            switch (resStatus) {
                case 200:
                    console.log('success')
                    setFormState(result)
                    break
                case 500:
                console.log('Default Error')
                    break
                default:
                    console.log('Default Error')
                    break
            }
            
            

        })
        .catch((error) => {
            switch(resStatus){
              case 500:
                alert('Please refresh your browser')
                break
              default:
                console.log('General Error');
                break
            }
          })

    }, []);

 
  if (formState) {

    return (
        <div className={modalOpen ? "App scroll-hidden" : "App"}> 
            
            {/* {businessClosed? 

                <Ads />     
                :
                null
            } */}
            
            <Helmet>
                <title>{formState.name}</title>
            </Helmet>
            
            {/* Only show call server if buzzer exits*/}
            {/* {(typeof(formState.callServer) != "undefined")&&
                <>
                    {(typeof(formState.callServer.statusOn) != "undefined" && formState.callServer.statusOn != null && formState.callServer.statusOn == true) ?
                        <CallServer buzzerURL={formState.callServer.buzzerURL} />
                        
                        :

                        null
                    }
                </>
            }
             */}
            
          <div className="parent-container">

            <ItemDetails open={modalOpen} onChange={handleModalChange} data={detailData} color={brand} />
            
            

            {formState.layout.tab ? 
                <>
                
                    <Tabs data={formState} openDetails={openDetails} />
                
                </>

                :
                <>
                <MenuHeader data={formState} /> 
                <ListView data={formState} openDetails={openDetails} />
                </>
            
            }
            

            
    
          </div>
          
        </div>
      );

    }  

    

    else return(

        <div className="loading"> 
            
            <div className="loadingCard">
                <div className="loading90"></div>
                <div className="loading60"></div>
                <div className="loading40"></div>
            </div>

            <div className="loadingCard">
                <div className="loading90"></div>
                <div className="loading60"></div>
                <div className="loading40"></div>
            </div>

            <div className="loadingCard">
                <div className="loading90"></div>
                <div className="loading60"></div>
                <div className="loading40"></div>
            </div>

            <div className="loadingCard">
                <div className="loading90"></div>
                <div className="loading60"></div>
                <div className="loading40"></div>
            </div>

            <div className="loadingCard">
                <div className="loading90"></div>
                <div className="loading60"></div>
                <div className="loading40"></div>
            </div>
        </div>

    );

    
  
}

export default Home;
