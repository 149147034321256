import React, { useEffect, useState, useRef } from "react";
import '../css/modal.css';
import '../App.css'
import {Link, useHistory } from "react-router-dom";
import Button from "./../components/button-comp.js"
import { TextField, CircularProgress } from '@material-ui/core';
import Card from "../img/features/card.png"
import ButtonTW from "./button-tw";
import { useUserContext } from "../context/user-context";

function Modal(props) {

    const [isOpen, setIsOpen] = useState(false);
    const inputVal = useRef();
    const { logoutUser } = useUserContext();
    

    function Close() {
        setIsOpen(!isOpen)
        props.onChange();
        document.body.classList.remove('body-modal-open');
    }



    function closeModal() {
        setIsOpen(!isOpen)
        document.body.classList.remove('body-modal-open');
    }


    

    useEffect(() => {
        if(isOpen) {
            document.body.classList.add('body-modal-open');
        } else {
            document.body.classList.remove('body-modal-open');
        }
    }, [isOpen]);


    useEffect(() => {
        setIsOpen(props.open);
    }, [props.open]);

    const Existing = () => {
        const val = inputVal.current.value
        if(val.length == 10){
            props.registerExist(val)
            
        } else {
            alert("Invalid Menu ID")
        
        }
        
    }




    if(isOpen) {

        return(
            <div className="lightbox">
                <div className="modalcustom !pt-4 !px-[5%]">

                    
                    
                    {props.modalContent == "not-reg" &&
                        <>
               
                            <h1 className="text-3xl pb-4">Thanks for signing up!</h1>
                            We are excited to help you get setup with up your menu.<br /> Please proceed to verify your e-mail address. 
                            
                            
                            <div className="line"></div>

                            <h2 className="modalh2">Verify your e-mail address</h2>
                            
                            Click the <i>send verification e-mail</i> button below. <br />
                            We will send an e-mail to <a href="javascript:void(0)">{props.user.email}</a>. Click the link in the e-mail to verify and proceed with access to your menu.

                            <div className="save-cancel-btn-group mt-10">
                                <ButtonTW style="primary">
                                    Send verification email
                                </ButtonTW>
                                <ButtonTW style="secondary" onClick={props.lout}>
                                    Cancel
                                </ButtonTW>
                            </div>
                           
                           
                            {/* Start from scratch by creating a brand new menu. You will be able to add your restaurants details, categories, items, descriptions etc.<br /> */}
                            {/* <Button style="pri" text="Create" onClick={props.registerNew} /> */}
                            {/* <a href="mailto:contactdamenus@gmail.com?subject=Da Menus -New Menu Request">Request access</a> */}

                            {/* <div className="save-cancel-btn-group mt-10">
                                <ButtonTW style="primary" onClick={props.registerNew}>
                                    Create
                                </ButtonTW>
                                <ButtonTW style="secondary" onClick={props.lout}>
                                    Cancel
                                </ButtonTW>
                            </div> */}

                            {/* <div className="line"></div>
                            <div className="mt-6">
                               Note: If you have aready verified your e-mail address and want to create a new menu, please reach out via <a href="mailto:contactdamenus@gmail.com?subject=New Menu Request">email</a>
                            </div> */}

                            {/* 
                            <h2 className="modalh2 pt-4">Need access to an existing menu?</h2>
                            
                            Please reach out via <a href="mailto:contactdamenus@gmail.com?subject=Edit My Menu Request">email</a>
                            <br /><br /> */}
                            {/* <a href="mailto:rahul@dabuzzer.com?subject=Edit My Menu Request">Get a Sign In Link</a> */}
                            {/* enter the Menu ID below and get started.<br /><br /> */}


                            {/* <TextField id="menuid" inputRef={inputVal} label="Enter Menu ID"  />
                            <br /><br />
                            <div className="modalCancel">
                                <Button style="pri" text="Submit" onClick={Existing} /> <br />
                                <Button style="ter" text="Cancel" onClick={props.lout} /> 
                            </div> */}

{/* 
                            <br /><br />

                            <a href="#"onClick={closeModal}>Close</a> */}

                            
                        </>
                    }

                    {/* {props.modalContent == "not-verified" &&
                        <div className="flex text-center justify-center items-center h-[100%] flex-col">
                            
                            <h1 className="text-3xl pb-4">Verify your email</h1>
                            
                            Your are a one step away from your menu.<br />
                            We sen't you a verification email on <br />
                            <div className="save-cancel-btn-group mt-10 justify-center">
                                <ButtonTW style="primary">
                                    Re-send verification email
                                </ButtonTW>
                                <ButtonTW style="secondary" onClick={props.lout}>
                                    Cancel
                                </ButtonTW>
                            </div>
                        </div>
                    } */}

                    {props.modalContent == "contact" &&

                        <>
                            <div className="modal-header">
                                <h1>Contact Us</h1>
                                <Link onClick={Close}>Close</Link>
                            </div>
                            <div>
                                <br />
                                Reach out and we will help you get setup
                                <br /><br />
                                
                                <div className="contact-text">
                                <b>Rahul Rahate</b><br />
                                <small>
                                    Founder - Da Menus
                                    <br />
                                Chicago, IL</small>
                                <br /><br />
                                
                                Email: <a href="mailto:contactdamenus@gmail.com">contactdamenus@gmail.com</a><br />
                                Cell: <a href="tel:315-560-3399">+1-315-560-3399</a>
                                </div>
                                
                            </div>
                        </>

                    }
                    {props.modalContent == "printing" &&
                        <>
                            <div className="modal-header">
                                <h1>Printing fees</h1>
                                <Link onClick={Close}>Close</Link>
                            </div>
                            <br />
                            <div>
                                Includes:<br />
                                Card Design, Review and Printing.

                            </div>
                            <div className="modal-section modal-section-parent">
                                <div>
                                    <img src={Card} />
                                </div>
                                <div>
                                    Business Card Size<br />
                                    3.5” x 2” <br /><br />
                                    
                                    <div className="modal-section modal-table">
                                        <div>
                                            <small>
                                                <b>Qty</b>
                                            </small>
                                            
                                            <br />500
                                            <br />1000
                                            <br />2000
                                        </div>

                                        <div>
                                            <small>
                                                <b>Price</b>
                                            </small>
                                            <br />$50
                                            <br />$100
                                            <br />$180
                                        </div>
                                    </div>

                                    <br />

                                    Delivery
                                    2 Weeks
                                </div>
                            </div>
                        </>
                    }

                    {Array.isArray(props.modalContent) &&
                        <div className="overflow-scroll">
                            <div className="modal-header">
                                <h1>Your Menus</h1>
                                <Link onClick={Close}>Close</Link>
                            </div>
                            <div>
                            {
                                props.modalContent.map((item, idx) => {
                                    return(
                                        <div className="modalLink">
                                            <Link key={idx} to={'/dashboard/' + item.menuID} onClick={closeModal}>
                                                {item.name}<br />
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    }     

                    {props.modalContent == "loader" &&

                        <>
                            <div className="loading"> 
                                <CircularProgress />
                            </div>
                        </>
                    }

                    {props.modalContent == "with-email" &&
                        <>
                            <h1>Register with Email</h1>
                            <input type="text" />
                            <input type="text" />
                            <input type="text" />
                        </>
                    }  

                    

                </div>
            </div>
    
        );

    }

    else return (
        null
    )
    

}

export default Modal;