import React, { useEffect, useState, useRef } from "react";
import "./../App.css"
import "firebase/auth"
import update from 'immutability-helper';
import { IconButton } from '@material-ui/core'
import { RemoveCircle, AddCircle, WarningOutlined, ArrowUpward, ArrowDownward, EditSharp, Delete, FileCopyRounded  } from '@material-ui/icons';
import {Helmet} from "react-helmet";
import styled from '@emotion/styled'
import ButtonTW from "../components/button-tw";
import { Link , useNavigate, useParams } from 'react-router-dom';
import MainLayout from "../components/nav/main-layout";
import { useUserContext } from "../context/user-context";
import Loading from "../components/utilities/loading";
import { PostData, checkUD, getData } from "../components/utilities/data-handling";
import PageAlert from "../components/alerts/page-alert";

function EditCustomerAlerts() {

    let { id } = useParams();
    
    const [formState, setFormState] = useState('');
    const { user } = useUserContext();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    useEffect(() => {
    
        if (user) {
          setLoading(false);
          getTheData()
          
        } 
  
    }, []);

    async function getTheData() {
        const data = await getData(id, user.uid);
        if(data) {
            setFormState(data);
        }

    }

    // POST DATA
    async function savedata() {
        let testdata;
        setLoading(true);
        PostData(formState, id).then((data) => {
            if(data == true){
                setLoading(false);
                setAlertType("success");
                setAlertMessage("Alerts successfully updated.");
                setShowAlert(true);
            } else {
                setLoading(false);
                setAlertType("error");
                setAlertMessage("Error. Please try again later.");
                setShowAlert(true);
            }
            
        });        
        
        
    }

    const addAlertsSection = () => {

        const obj = {alertTitle: "Placeholder Title", alertCopy: "Placeholder Copy"}
        const newData = update(formState, {
            alerts: {
                $set: [obj]
            },
        });

        setFormState(newData);
        
    }

    const handleAddSec = (alertItemIndex) => {
    
        const obj = {alertTitle: "Placeholder Title", alertCopy: "Placeholder Copy"}
        const newData = update(formState, {
          alerts: {
            $splice: [[alertItemIndex, 0, obj]]
          },
        });
    
        setFormState(newData);
        
    
    }

    const handleRemoveSec = (alertItemIndex) => {
    
    
        const newData = update(formState, {
          alerts: {
            $splice: [[alertItemIndex, 1]]
          },
        });
    
        setFormState(newData);
        
    
    }

    const handleItemChange = (event, alertItemIndex) => {
    
        const name = event.target.name
        const val = event.target.value
        console.log("name: ", event.target.name)
        console.log("val: ", event.target.value)
        console.log("index: ", alertItemIndex)
        

        const newData = update(formState, {
            alerts: {
                [alertItemIndex] :{
                    [name]: {$set: val}
                }
            },
        });


        setFormState(newData);

        
      };
    
      
    return(
        <MainLayout>
            <h3 className="text-3xl font-bold">Customer Alerts</h3>
            <div className="text-md">Customers will see alerts in the about section of your menu</div>

            {formState&& 
                <Helmet>
                    <title>Customer Alerts - {formState.name}</title>
                </Helmet>
            }

            <div className="mt-6">

                {checkUD(formState.alerts) && formState.alerts.length !== 0 ? 
                    <>
                        {formState.alerts.map((alertitem, alertindex) => {
                            return (
                            <div className="customerAlertCard">
                                <div className="CardCont" key={alertindex}>
                                    

                                    <div className="form-control">
                                        <label className="label pl-0">
                                            <span className="label-text text-dmbasetext">Message Title</span>
                                        </label>    
                                        <input 
                                            type="text"
                                            className="input input-bordered"
                                            value={alertitem.alertTitle}
                                            onChange={event => handleItemChange(event, alertindex)} 
                                            name="alertTitle" 
                                        />
                                        
                                    </div>

                                    <div className="form-control">
                                        <label className="label pl-0">
                                            <span className="label-text text-dmbasetext">Message Copy</span>
                                        </label>    
                                        <input 
                                            type="text"
                                            className="input input-bordered"
                                            value={alertitem.alertCopy}
                                            onChange={event => handleItemChange(event, alertindex)} 
                                            name="alertCopy"  
                                        />
                                        
                                    </div>

                                    <div className="icon-buttons-group">
                                
                                        <IconButton
                                            onClick={() => handleAddSec(alertindex)}
                                        >
                                            <AddCircle />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleRemoveSec(alertindex)}
                                        >
                                            <Delete />
                                        </IconButton>

                                    
                                    </div>

                                    </div>  
                                

                                </div>
                            )
                        })}

                        <div className="border-[0.75px] my-10 border-[#ddd]"></div>
                        <div className="save-cancel-btn-group mt-10">
                            <ButtonTW style="primary" onClick={savedata}>
                                Save
                            </ButtonTW>
                            <ButtonTW style="secondary">
                                Cancel
                            </ButtonTW>
                        </div>
                
                        {showAlert&&
                            <PageAlert message={alertMessage} type={alertType} />
                        } 
                    </>

                    :

                    <div> 
                        
                        <div className="alert bg-dmwhite shadow-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <div>
                            <h3 className="font-bold">No Customer Alerts</h3>
                            {/* <div className="text-xs">You have 1 unread message</div> */}
                        </div>
                        <button className="btn btn-sm" onClick={addAlertsSection}>
                            Add an alert
                        </button>
                        </div>

                        <div className="save-cancel-btn-group mt-10">
                            <ButtonTW style="primary" onClick={savedata}>
                                Save
                            </ButtonTW>
                            <ButtonTW style="secondary">
                                Cancel
                            </ButtonTW>
                        </div>

                    </div>
                }

            </div>

            

            
            {loading && 
                <Loading />
            }
            
            
        </MainLayout>
    )
}

export default EditCustomerAlerts;