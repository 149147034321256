import React, { useEffect, useState } from "react";
import '../css/item-details.css';
import { Cancel, ArrowBackIosOutlined, Clear} from '@material-ui/icons';
import { IconButton } from '@material-ui/core'
import { css } from '@emotion/css'

function ItemDetails(props) {

  const [isOpen, setIsOpen] = useState(false)
  const [detailData, setDetailData] = useState('')
  
  useEffect(() => {
    setIsOpen(props.open);
  }, [props.open]);

  function handleModalClose() {
    setIsOpen(!isOpen)
    props.onChange();
  }

  useEffect(() => {
    setDetailData(props.data)
  },[props.data])

  if(isOpen) {
    return (
      
        <div className="item-details">
          <div 
              className={css`
                  background-color: ${((props.color == undefined)||(props.color.background == null)||(props.color.background == "")) ? '#ed6a5a' : props.color.background};
                  width: 100%;
                  height: 80px;
                  position: relative;
                  padding-right: 20px;
                  justify-content: flex-end;
                  align-items: center;
                  display: flex;
              `}
            
            >
              <div onClick={handleModalClose} 
                className={css`
                    color: ${((props.color == undefined)||(props.color.textcolor == null)||(props.color.textcolor == "")) ? '#ffffff' : props.color.textcolor};
                `}
              >
                  <Clear />
              </div>
            </div>
          { detailData.imgurl ?


            <div style={{ backgroundImage: `url(${detailData.imgurl})` }} className="details-img">
              {/* <IconButton onClick={handleModalClose} className="close-btn">
                  <Cancel />
              </IconButton> */}
            </div>

            :

            null
          
          }
          
          <div className="text-content">
            <h1>{detailData.title}</h1>
            <div className="desc">{detailData.desc.replace(/\\n/g,'\n')}</div>
            <div className="price">{detailData.price.replace(/\\n/g,'\n')}</div>
          </div>

          
          
        </div>
      
    );
  }
  else {
    return (
      null
    );
  }
}

export default ItemDetails;
