import React, { useEffect, useState, useRef } from "react";
import "./../App.css"

import LayoutCard from "../components/dash/layout-card.js"
import MainLayout from "../components/nav/main-layout";
import { Snackbar, CircularProgress, Button } from '@material-ui/core'
import Modal from  "./../components/modal.js"
import {Helmet} from "react-helmet";
import { Link , useNavigate, useParams } from 'react-router-dom';
import { css, cx } from '@emotion/css'
import styled from '@emotion/styled'
import { mq } from "../components/utilities/media-queries.js";
import laminated from "../img/dash/laminated.png"
import analytics from "../img/dash/Analytics.png"
import togo from "../img/dash/ToGo.png"
import { useUserContext } from "../context/user-context";

let resStatus = 0; 

const Card = styled.div`
  
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  border-radius: 10px;
  margin: 10px 0px 30px 0px;
`

const Layout30 = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    width: 100%;
  }


  ${mq[1]} {
    
    flex-direction: row;

    & > div {
      width: 31%;
    }
  }

`

const Layout = styled.div`
  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  

`


const CardLink = styled.div`
  border-top: 1px solid #ccc;
  text-align: left;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const CardContent = styled.div`
  padding: 16px;
`

const CardHead = styled.div`
  padding: 16px;
  border-bottom: 1px solid #ccc;
`

const Small = styled.small`
  color: #555;
`

const H3 = styled.h3`
  margin:0;
  font-size: 1.4em;
  
`

const Image = styled.img`
  max-width: 100%;
  display: block;
  border-radius: 10px;
`

const H4 = styled.h4`
  margin:0;
  margin-bottom: 10px;
`

const Colors = styled.div(
  {
    width: '40px',
    height: 'auto',
    border: '1px solid #222',
    borderRadius: '5px',
    marginRight: '10px'
  },
  props => ({
    background: props.background
  })
)


const ColorRep = styled('div')`
  display: flex;
  flex-direction: row;
  aling-items: center;
  margin-right: 20px;
  margin-bottom: 0;
  
  
  & small {
    color: #777;
    font-size: 0.7em;
    text-transform: uppercase;
  }
  @media (max-width: 767px) {
    width: 50%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
`


function Dash() {

    let { id } = useParams();
    const navigate = useNavigate();
    const [formState, setFormState] = useState('');
    const [signedIn, setSignedIn] = useState(false);
    const [loading, setloading] = useState(true);
    const [layoutTab, setLayoutTab] = useState();
    const [open, setOpen] = useState(false);
    const [loadingCopy, setLoadingCopy] = useState("Loading...")
    const [noAccess, setNoAccess] = useState(false);
    const [loginState, setLoginState] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)
    const apiUrl = process.env.REACT_APP_API_EXT
    const [modalOpen, setModalOpen] = useState(false)

    const handleModalChange = () => {
        setModalOpen(!modalOpen)
    }

    const { user } = useUserContext();
  
  // const moveOver = () => {

  //   const requestOptions = {
  //     method: 'POST',
  //     headers: { 
  //         'Content-Type': 'application/json',
      
  //     },
  //         body: JSON.stringify(formState)
  //     };
        
  //     fetch( "http://localhost:5000/da-menus-15b3f/us-central1/api/move" + id, requestOptions)
  //     .then(response => {
  //         resStatus = response.status;
  //     })
  //     .then(
  //         (result) => {
  //             switch (resStatus) {
  //             case 200:
  //                 console.log('success');
  //                 break
  //             }
  //         },
  //     )
  //     .catch((error) => {
  //         console.log(error)
  //     })

  // }

  useEffect(() => {

    if(navigate.state != undefined && navigate.state.saved == true){
      setOpen(true)
    }
  },[]);

  const handleClose = () => {
    setOpen(false)
  }

  
  useEffect(() => {
    
    
      if (user) {
        // console.log(user.uid)
        setSignedIn(true)
        setLoginState(true)
        setloading(false);
        getData(user.uid);
      } else {
        // No user is signed in.
        setSignedIn(false)
        setloading(false);
      }
    
    
      
  }, []);

  const updateLayout = () => {

        setUpdateLoading(true)
        
        setFormState({
          ...formState,
          layout: {
            ...formState.layout,
            tab : !formState.layout.tab
          }
    
        })
        

  }

useEffect(() => {
  
  if(updateLoading) {
    
    postData();
    
  } 

},[formState])



const postData = () => {

  const requestOptions = {
    method: 'POST',
    headers: { 
        'Content-Type': 'application/json',
    
    },
        body: JSON.stringify(formState)
    };

    fetch( apiUrl + "/menu/" + id, requestOptions)
    .then(response => {
        resStatus = response.status;
    })
    .then(
        (result) => {
            switch (resStatus) {
            case 200:
                console.log('success');
                setUpdateLoading(false)
                break
            }
        },
    )
    .catch((error) => {
        switch(resStatus){
        case 500:
            alert('Error, please try again')
            break
        default:
            console.log('General Error');
            break
        }
    })
  }



  const getData = (uuid) => {
    
      
    fetch(apiUrl + "/menu/" + id + "/" + uuid)
      .then(res => {
          resStatus = res.status;
          return res.json();
      })
      .then(
        (result) => {

          switch (resStatus) {
              case 200:
                  setFormState(result)
                  break
              
          }

        }
      )
      .catch((error) => {
        console.log("Error", error)
        switch(resStatus) {
          // case 404:
          //   alert('Your are not autorized to view edit this menu');
          //   break    
          case 500:
            console.log('Your are not autorized to view edit this menu')
            setNoAccess(true)
            break
          default:
            console.log('Default Error')
            break
        }
      })

    }

    if(!loading) {
        return(
          <div className="App"> 
              <MainLayout>
                
                <Modal open={modalOpen} onChange={handleModalChange} modalContent="contact"  />
                <div className="">

                    {formState &&
                        <>
                          <Helmet>
                            <title>Dashboard - {formState.name}</title>
                          </Helmet>

      
                          <div className="alert alert-info items-start mb-10 flex flex-row text-left">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <span className="break-keep text-xs">
                              <b>Important</b><br />
                                Any Updates to menu will only show on the live website ( https://da-menus.com/menu/{id}) after 24 hours. Please contact us for any further assistance.
                              
                              {/* <br/><br /> <b>Note:</b><br />
                              <b>The First Load</b> of your menu will take a couple of seconds. Every subsequent load is instantaneous. <br />
                              This process is in place to significantly increase your menus load time.<br /> */}
                            </span>
                          </div>

                            <Card>
                              <CardHead>
                                <H3>Tabletop QR Codes</H3>
                                <Small>10 Free Laminated Codes</Small>
                              </CardHead>
                              <CardContent>
                                <Image src={laminated} />
                              </CardContent>
                              <CardLink>
                                <Link onClick={handleModalChange}>Contact us for your FREE qr codes</Link>
                              </CardLink>
                            </Card>

                            <Card>
                              <CardHead>
                                <H3>To-Go QR Codes</H3>
                                <Small>Business card sized to staple on to To-Go Bags</Small>
                              </CardHead>
                              <CardContent>
                                <Image src={togo} />
                              </CardContent>

                              <CardLink>
                                <Link onClick={handleModalChange}>Order to-go qr codes</Link>
                              </CardLink>

                            </Card>

                            {/* <Card>
                              <CardHead>
                                <H3>Analytics</H3>
                                <Small>Available with Premium</Small>
                              </CardHead>
                              <CardContent>
                                <Image src={analytics} />
                              </CardContent>
                              <CardLink>
                              <Link onClick={handleModalChange}>Request analytics data</Link>
                              </CardLink>
                            </Card> */}

                          

                         

                          



                        </>
                    }
                    
                </div>

                <Snackbar
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    open={open}
                    autoHideDuration={3000}
                    message="Data Saved"
                    onClose={handleClose}
                />

              </MainLayout>
          </div>
        );
    }
   
    else return(

        <div className="loading"> 
          <CircularProgress />
          <div>{loadingCopy}</div>
        </div>
    
    );
}

export default Dash;