import React, { useEffect, useState } from "react";
import "./../App.css"
import MainLayout from "../components/nav/main-layout";
import update from 'immutability-helper';
import { Snackbar, IconButton, CircularProgress, Button } from '@material-ui/core'
import { RemoveCircle, AddCircle, WarningOutlined, ArrowUpward, ArrowDownward, EditSharp, Delete, FileCopyRounded  } from '@material-ui/icons';
import {Helmet} from "react-helmet";
import { Link , useHistory, useParams, useNavigate } from 'react-router-dom';
import EditItem from "./../components/edit-item.js"
import ItemCard from "./../components/item-card.js"
import styled from '@emotion/styled'
import { useUserContext } from "../context/user-context";
import ButtonTW from "../components/button-tw";
import Loading from "../components/utilities/loading";

const H3 = styled.h3`
  font-size: 1.4em;
  
`




let resStatus = 0; 


function Edit() {

  // const id = "abc12";
  let { id } = useParams();
  const [formState, setFormState] = useState('');
  const [signedIn, setSignedIn] = useState(false);
  const [loading, setloading] = useState(true);
  const [loadingCopy, setLoadingCopy] = useState("Loading...")
  const [noAccess, setNoAccess] = useState(false);
  const [saved, setSaved] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [passedVal, setPassedVal] = useState('');
  const [saveThisData, setSaveThisData] = useState(false);
  const [onPageChange, setOnPageChange] = useState(false);
  const [loginState, setLoginState] = useState(false)
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarContent, setSnackBarContent] = useState("")
  const navigate = useNavigate();
  
  const { user } = useUserContext();
  

  const apiUrl = process.env.REACT_APP_API_EXT


  const handleSnackBarClose = () => {
    setSnackBarContent("")
    setSnackBarOpen(false)
  }



  // useEffect(() => {
  //   // Update the document title using the browser API
  //   firebase.auth().onAuthStateChanged(function (user) {
  //     if (user) {
  //       setLoginState(true);
        
  //     } else {
  //       // No user is signed in.
  //       setLoginState(false);
  //     }
  //   });
  // });


  useEffect(() => {
    if(saveThisData) {
      postData();
    }
  }, [saveThisData]);

  useEffect(() => {
    if(saved) {
      setloading(false);
      setLoadingCopy("Loading...");
      setSaved(false);
      setSaveThisData(false);
      setOnPageChange(false)
    }
  },[saved]);

  useEffect(() => {
    
      if (user) {
        setSignedIn(true)
        setloading(false);
        getData(user.uid);
      } else {
        // No user is signed in.
        setSignedIn(false)
        setloading(false);
      }
    
    
      
  }, []);

  const getData = (uuid) => {
    
      
    fetch(apiUrl + "/menu/" + id + "/" + uuid)
      .then(res => {
          resStatus = res.status;
          return res.json();
      })
      .then(
        (result) => {

          switch (resStatus) {
              case 200:
                  setFormState(result)
                  setSaved(true)
                  break
              
          }

        }
      )
      .catch((error) => {
        console.log("Error", error)
        switch(resStatus) {
          // case 404:
          //   alert('Your are not autorized to view edit this menu');
          //   break    
          case 500:
            console.log('Your are not autorized to view edit this menu')
            setNoAccess(true)
            break
          default:
            console.log('Default Error')
            break
        }
      })

  }



  const handleUpDown = (categoryindex, val) => {
    
    const currentArray = {
      ...formState,
      menu: [
        ...formState.menu,
      ]
    }

    const catval = currentArray.menu[categoryindex]

    console.log(catval)

    const newData = update(formState, {
      menu: {
        $splice: [[categoryindex, 1]],
      },
    });

    const data2 = update(newData, {
      menu: {
        $splice: [[categoryindex+(val), 0, catval]],
      },
    });

    setFormState(data2)
    setOnPageChange(true)

  }

  const handleItemUpDown = (categoryindex, itemIndex, val) => {
    
    const currentArray = {
      ...formState,
      menu: [
        ...formState.menu,

      ]
    }

    const itemval = currentArray.menu[categoryindex].items[itemIndex]

    console.log(itemval)

    const newData = update(formState, {
      menu: {
        [categoryindex]: {
          items :{
            $splice: [[itemIndex, 1]]
          }
        }
      },
    });
    
    const data2 = update(newData, {
      menu: {
        [categoryindex]: {
          items :{
            $splice: [[itemIndex+(val), 0, itemval]],
          }
        }
      },
    });

    setFormState(data2)
    setOnPageChange(true)

  }

  


  // ADD CATEGORY
  
  const handleAddCategory = (categoryindex) => {
    
    const obj = {category:"New Category", items: [{imgurl: "", title: "", desc: "", price: "" }]}
    const newData = update(formState, {
      menu: {
        $splice: [[categoryindex, 0, obj]]
      },
    });

    setFormState(newData);
    setOnPageChange(true)

  }

  // ADD CATEGORY
  
  const handleCopyCategory = (categoryindex) => {
    
    const currentArray = {
      ...formState,
      menu: [
        ...formState.menu,
      ]
    }

    const catval = currentArray.menu[categoryindex]

    const newData = update(formState, {
      menu: {
        $splice: [[categoryindex, 0, catval]]
      },
    });

    setFormState(newData);
    setOnPageChange(true)

  }


  const handleBrandAddCategory = (categoryindex) => {
    
    if(formState.menu) {
      const obj = {category:"New Category", items: [{imgurl: "", title: "", desc: "", price: "" }]}
      const newData = update(formState, {
        menu: {
          $push: [obj]
        },
      });

      setFormState(newData);
      setOnPageChange(true)
      
    } else {
      
      const obj = {category:"New Category", items: [{imgurl: "", title: "", desc: "", price: "" }]}
      const newData = update(formState, {
        menu: {
          $set: [obj]
        },
      })

      setFormState(newData);
      setOnPageChange(true)
    }


  }


  // REMOVE CATEGORY

  const handleRemoveCategory = (categoryindex) => {

    const currentArray = {
      ...formState,
      menu: [
        ...formState.menu,
      ]
    }

    const catval = currentArray.menu[categoryindex]
    const items = catval.items

    const removeCat = () => {
      const newData = update(formState, {
        menu: {
          $splice: [[categoryindex, 1]]
        },
      });

      setFormState(newData);
      setOnPageChange(true)
    }

    if(items.length != 0) {

 

      for (let i = 0; i < items.length; i++) {
        if(items[i].imgurl == "") {
          removeCat()
        } else {
          setSnackBarContent("Please remove items with an image from category before deleting the category.")
          setSnackBarOpen(true)
        }

      }


    } else {
        removeCat()
    }

    

  }

  // ADD ITEM

  const handleAddItem = (categoryindex, itemIndex) => {

    const obj = {imgurl: "", title: "New Item", desc: "", price: "" }
    const newData = update(formState, {
      menu: {
        [categoryindex]: {
          items :{
            $splice: [[itemIndex+1, 0, obj]]
          }
        }
      },
    });

    setFormState(newData);
    setOnPageChange(true)

  }

  // COPY ITEM

  const handleCopyItem = (categoryindex, itemIndex) => {

  
    const currentArray = {
      ...formState,
      menu: [
        ...formState.menu,
      ]
    }

    const itemval = currentArray.menu[categoryindex].items[itemIndex]


    const newData = update(formState, {
      menu: {
        [categoryindex]: {
          items :{
            $splice: [[itemIndex+1, 0, itemval]]
          }
        }
      },
    });

    setFormState(newData);
    setOnPageChange(true)

  }


  // REMOVE ITEM

  const handleRemoveItem = (categoryindex, itemIndex, passedurl) => {
    if(passedurl != "") {
      setSnackBarContent("Please remove image from item before deleting the item.")
      setSnackBarOpen(true)

    } else {
      
      const newData = update(formState, {
        menu: {
          [categoryindex]: {
            items :{
              $splice: [[itemIndex, 1]]
            }
          }
        },
      });
  
      setFormState(newData);
      setOnPageChange(true)
    }
    

  }


  // POST DATA

  const postData = () => {
    setLoadingCopy("Saving Menu");
    setloading(true);

    const requestOptions = {
      method: 'POST',
      headers: { 
          'Content-Type': 'application/json',
      
      },
          body: JSON.stringify(formState)
      };
      

      fetch( apiUrl + "/menu/" + id, requestOptions)
      .then(response => {
          resStatus = response.status;
      })
      .then(
          (result) => {
            switch (resStatus) {
              case 200:
                  console.log('success');
                  setSaved(true);
                  break
            }
          },
      )
      .catch((error) => {
        switch(resStatus){
          case 500:
            alert('Please refresh your browser')
            break
          default:
            console.log('General Error');
            break
        }
      })
  }



  function passCatValues(passCat, passCatDesc, catindex) {
    var val = {
      category: passCat,
      categoryDesc: passCatDesc,
      cat: catindex,
      editingcat: true,
    }

    setPassedVal(val);
    setEditItem(true)

  }

  function passResName(passName) {
    var val = {
      name: passName,
      editingcat: true
    }

    setPassedVal(val);
    setEditItem(true)

  }


 

  function passValues(passimg, passtitle, passdesc, passprice, catindex, itemind) {

    var val =  {
      imgurl: passimg,
      title: passtitle,
      desc: passdesc,
      price: passprice,
      cat: catindex,
      itm: itemind
    }


    setPassedVal(val);
    setEditItem(true);

  }


  function saveItemData(x) {
    
    const formatData = {
      imgurl: x.imgurl,
      title: x.title,
      desc: x.desc,
      price: x.price,
      
    }

    

    let newData = ''

    if(x.name != null) {
      newData = update(formState, {
        name: {$set: x.name}  
      });
      
    }

    else if(x.category != null) {

      newData = update(formState, {
        menu: {
          [x.categoryindex]: {
            category: {$set: x.category}, 
            categoryDesc: {$set: x.categoryDesc}  
          }
        },
      });

    }

    else if(x.title !=null) {
      newData = update(formState, {
        menu: {
          [x.categoryindex]: {
            items :{
              $splice: [[x.itemIndex, 1, formatData]]
            }
          }
        },
      });
    }

    
    
    setFormState(newData);
    // setEditItem(false);
    setSaveThisData(true);
    
  }

  function closeModal() {
    setEditItem(false);
  }

  
  
  return (
      <div className="App">  
        <MainLayout>

        { signedIn
          
          ?
          <>
            {noAccess ?
              
              <div className="edit-error-container red-container">  
                <WarningOutlined />
                <h3>
                  You do not have acess to edit this menu
                </h3>
                
              </div>
              
              :
              
              <>

                {editItem ? 
                  <div>
                    <EditItem title={passedVal.title} desc={passedVal.desc} imgurl={passedVal.imgurl} price={passedVal.price} cat={passedVal.cat} itm={passedVal.itm} editingcat={passedVal.editingcat} category={passedVal.category} name={passedVal.name} categoryDesc={passedVal.categoryDesc} data={(value) => saveItemData(value)} cancel={() => closeModal()} saveSuccess={saveThisData} />
                  </div>

                  :
                  null
                }
                {onPageChange?
                  <div className="flex justify-center">
                    <div className="save-cancel-edit-page flex gap-4">
                        {/* <Button variant="contained" color="primary" size="large" >Save</Button>      */}
                        <ButtonTW style="primary" onClick={postData}>Save</ButtonTW>
                        <ButtonTW style="secondary" onClick={() => getData(user.uid)}>Cancel</ButtonTW>
                    </div>
                  </div>
                  : 
                    
                  null
                }

                <div className={editItem ? "hidden" : "block"}>
                  
                  {formState &&
                    <>
                      <Helmet>
                        <title>Edit Menu - {formState.name}</title>
                      </Helmet>
                      <h3 className="text-3xl font-bold">Menu</h3>
                      <div className="text-md mb-6">Edit menu details, add images and prices</div>

    
                      {/* <TextField 
                        label="Restaurant Name" 
                        name="nameField" 
                        // id="nameField" 
                        maxLength="300" 
                        onChange={handleNameChange} 
                        value={formState.name}
                        variant="filled"
                        /> 
                        <div className="category-edit">
                          <div className="edit-category-name">
                            <small>Restaurants Name</small>
                            <h2>{formState.name}</h2>
                          </div>

                          <div className="icon-buttons-group">
                            <IconButton
                                onClick={() => passResName(formState.name) }
                            >
                              <EditSharp />
                            </IconButton>
                          </div>
                        </div>
    
                        <div className="line"></div>
                        */}
                      </>
                      
                  }
                  
                  {formState.menu && formState.menu.map((menuitem, categoryindex) => {
                        return (
                          <div key={categoryindex}>
                            
                               <div className="category-edit">

                                  <div className="edit-category-name">
                                    <small>Category Name</small>
                                    <h2>{menuitem.category}</h2>
                                    <small>{menuitem.categoryDesc}</small>
                                  </div>
                                  
                                  <div className="icon-buttons-group">
                                    <IconButton
                                        onClick={() => passCatValues(menuitem.category, menuitem.categoryDesc, categoryindex) }
                                    >
                                      <EditSharp />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => handleAddCategory(categoryindex)}
                                    >
                                      <AddCircle />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => handleCopyCategory(categoryindex)}
                                    >
                                      <FileCopyRounded />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => handleRemoveCategory(categoryindex)}
                                    >
                                      <Delete />
                                    </IconButton>

                                    <IconButton
                                      onClick={() => handleUpDown(categoryindex, -1)}
                                    >
                                      <ArrowUpward />
                                    </IconButton>

                                    <IconButton
                                      onClick={() => handleUpDown(categoryindex, 1)}
                                    >
                                      <ArrowDownward />
                                    </IconButton>
                                    
    
                                  </div>
    
                              </div>
                              
                              <div className="itemsDisplay">
                              {
                                menuitem.items.map((item, itemIndex) => {
                                  return(
                                    <div key={itemIndex}>
                                      
                                        <div className="edit-page-card" >
                                          

                                            <ItemCard title={item.title} imgurl={item.imgurl} desc={item.desc} price={item.price}  />
                                      
                                            <div className="icon-buttons-group">
                                              <IconButton
                                                  onClick={() => passValues(item.imgurl, item.title, item.desc, item.price, categoryindex, itemIndex) }
                                              >
                                                <EditSharp />
                                              </IconButton>

                                              <IconButton
                                                onClick={() => handleAddItem(categoryindex, itemIndex)}
                                              >
                                                <AddCircle />
                                              </IconButton>
                                              <IconButton
                                                onClick={() => handleCopyItem(categoryindex, itemIndex)}
                                              >
                                                <FileCopyRounded />
                                              </IconButton>
                                              <IconButton
                                                onClick={() => handleRemoveItem(categoryindex, itemIndex, item.imgurl)}
                                              >
                                                <Delete />
                                              </IconButton>

                                              <IconButton
                                                onClick={() => handleItemUpDown(categoryindex, itemIndex, -1)}
                                              >
                                                <ArrowUpward />
                                              </IconButton>

                                              <IconButton
                                                onClick={() => handleItemUpDown(categoryindex,itemIndex, 1)}
                                              >
                                                <ArrowDownward />
                                              </IconButton>
                                            </div>

                                        </div>
    
                                    </div>
                                  )
                                })
                                
                              } 
                            </div>
                            <br />
                            
    
                            
                            <div className="line"></div>
                          </div>
                        )
                      }
                    )
                  }
                
                  
                  {formState &&
                    <>
                    <br />
                    <div className="add-item-link-group" onClick={handleBrandAddCategory}>
                      <AddCircle /> Add a category
                    </div>
                    <br /><br /><br /><br /><br />
                    </>
                  }
    
                </div>

                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                    open={snackBarOpen}
                    autoHideDuration={4000}
                    message={snackBarContent}
                    onClose={handleSnackBarClose}
                />

              </> 
            }

          </>
        
        :
        
        <div className="edit-error-container red-container">  
          <WarningOutlined />
          <h3>
            Please sign in to Edit the Menu
          </h3>
          
        </div>
          

        }

        {loading && <Loading />  }
        </MainLayout>
      </div>
  );


 


  
  
}



export default Edit;
