import React, { useEffect, useState, forwardRef } from "react";
import { css, cx } from '@emotion/css'
import Alert from './alert.js'
import styled from '@emotion/styled'

const AlertCarousel = styled.div`
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100% !important;
    overflow-x: scroll;

`

function MenuHeader(props, ref) {


    useEffect(() => {
        console.log(props.data)
    },[props.data])


    return(

        <div 
                className={css`
                
                    background-color: ${((props.data.brand == undefined)||(props.data.brand.background == null)||(props.data.brand.background == "")) ? '#ed6a5a' : props.data.brand.background};
                    color: ${((props.data.brand == undefined)||(props.data.brand.textcolor == null)||(props.data.brand.textcolor == "")) ? '#fff' : props.data.brand.textcolor};
                    padding: 20px 5vw;
                    word-break: keep-all;
                    width: 100%;
                
                `}

                ref={ref}
            
            >
  
                <h1 className={css`
                    word-break: keep-all;
                    white-space: pre-wrap;              
                `}
                >
                    {props.data.name.replace(/\\n/g,'\n')}
                </h1>

                {(typeof(props.data.businessDetails) != "undefined")&&
                    <>
                    {(typeof(props.data.businessDetails.address) != "undefined" && props.data.businessDetails.address != null) ?
                        
                        <small>{props.data.businessDetails.address}</small>

                        :

                        null

                    } 

                    {/* ${ props.data.brand.textColor ?  textColor : '#fff'}; */}
                    {/* color: ${((props.data.brand == undefined)||(props.data.brand.textcolor == null)||(props.data.brand.textcolor == "")) ? '#fff' : props.data.brand.textcolor}; */}
                    {(typeof(props.data.businessDetails.tel) != "undefined" && props.data.businessDetails.tel != null) ?
                        <div>
                            <a 
                                className={css`
                        
                                    color: ${((props.data.brand == undefined)||(props.data.brand.textcolor == null)||(props.data.brand.textcolor == "")) ? '#fff' : props.data.brand.textcolor};
                                
                                `}
                                
                                href={'tel:' + props.data.businessDetails.tel}
                            >
                                <small>{props.data.businessDetails.tel}</small>
                            </a>
                        </div>

                        :

                        null

                    } 

                    
                    </>
                }
                
                {(typeof(props.data.alerts) != "undefined")&&
                
                    <AlertCarousel>
                        {props.data && props.data.alerts.map((alerts, ixa) => {
                                return (
                                    
                                    <Alert key={ixa} color={props.data.brand} AlertTitle={alerts.alertTitle} AlertCopy={alerts.alertCopy} />
                                    
                                );
                            }
                        )}
                        
                    </AlertCarousel>
                }
                
        </div>

    );
}

export default forwardRef(MenuHeader);