import Home from "./home";
import { useParams } from 'react-router-dom';

function MenuPage() {

    const { id } = useParams();

    return (
        <>
            <div className="alert alert-info items-start m-10 w-[90%]">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <span className="break-keep">
                              <b>Important</b><br />
                                Items or data shown below are the most up to date.<br />
                                Items or data on the page https://da-menus.com/menu/{id} will recieve new data every 24 hrs
                            </span>
                          </div>
            <Home />
        </>

    );

}

export default MenuPage