import React, { useEffect, useState } from "react";
import '../css/item-card.css';

function ItemCard(props) {

  return (
    
      <div className="item-card">
       
        <div className="item-card-content">
          <div className="item-card-content-left">
            <div className="item-title">
              {props.title}
            </div>
            { props.desc ?

              <div className="item-desc">
                {props.desc.replace(/\\n/g,'\n')}
              </div>

              :

              null

            } 
            
          </div>

          <div className="item-card-content-right">
            {props.price.replace(/\\n/g,'\n')}
          </div>
        </div>

        { props.imgurl ?

          <div className="image">
            <img src={props.imgurl} />
          </div>

          :

          null

        }
        
      </div>
    
  );
}

export default ItemCard;
